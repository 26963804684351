import React, { useEffect, useRef, useState } from "react"

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Menu,
  Pagination,
  Paper,
  Stack,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  styled,
} from "@mui/material"
import { useRequest } from "ahooks"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { shallow } from "zustand/shallow"

import { coins } from "@/service"
import { addToast } from "@/state"

import ProfileMenu from "../components/ProfileMenu"
import CoinIcon from "../images/CoinIcon"
import { getIncomList, getUsageList } from "../service/api"
import useDialogState from "../state/dialog"
import useUserInfo from "../state/user"
import { API } from "../types"

const WhiteButton = styled(Button)({
  color: "#333",
  backgroundColor: "white",
  minWidth: 100,
  "&:hover": {
    backgroundColor: "#eee",
  },
})

const FreeCoinsTip: React.FC<API.VipInfo> = ({ free }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "0",
        right: "-90px",
        borderRadius: "8px 8px 8px 1px",
        padding: "3px 6px",
        fontSize: "small",
        color: "#CCAF93 ",
        bgcolor: "white",
      }}
    >
      今日赠送:5, 剩余:{free}
    </Box>
  )
}

// 积分的使用和收益情况展示
const WalletPage = () => {
  const navigate = useNavigate()
  const [user, vipInfo, usedTimes] = useUserInfo(
    (state) => [state.user, state.vip, state.usedTimes],
    shallow,
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [tabIndex, setTabIndex] = useState(1)
  const avatarRef = useRef<HTMLDivElement>(null)
  const profileVisible = useDialogState((state) => state.profileVisible)
  // 在获取焦点时候刷新一次积分信息N (手机上无法定时刷新)
  useRequest(coins.loadVipInfo, {
    refreshOnWindowFocus: true,
  })
  const handleClose = () => {
    useDialogState.setState({ profileVisible: false })
  }

  return (
    <Box>
      <CssBaseline />
      <AppBar elevation={1} color="inherit" sx={{ borderRadius: 0 }} component="nav">
        <Toolbar>
          <img
            onClick={() => navigate("/")}
            src="https://h5.qihuaitech.com/files/gpt_logo_blue.svg"
            style={{ width: 40, height: 40, borderRadius: 6 }}
          />
          <Typography variant="h6" component="div" ml={2} flex={1}>
            文生万物
          </Typography>
          <Avatar
            src={user?.avatar}
            ref={avatarRef}
            onClick={(e) => {
              setAnchorEl(e.currentTarget as HTMLDivElement)
              useDialogState.setState({ profileVisible: true })
            }}
          />
        </Toolbar>
        <Menu
          elevation={3}
          id="account-menu"
          anchorEl={anchorEl}
          open={profileVisible}
          onClose={handleClose}
          sx={{
            "& .MuiMenu-list": {
              p: 1,
              minWidth: "218px",
            },
          }}
        >
          <ProfileMenu />
        </Menu>
      </AppBar>
      <Container maxWidth="md" sx={{ p: 0 }}>
        <Paper sx={{ height: "100vh", borderRadius: 0 }}>
          <Toolbar />
          <Box
            sx={{
              background: "radial-gradient(circle, #DCC6AE, #C7A98B)",
              color: "white",
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box alignSelf="start">当前积分</Box>
            <Box position="relative" p={2}>
              <Typography variant="h3">{usedTimes}</Typography>
              {vipInfo && <FreeCoinsTip {...vipInfo} />}
            </Box>
            <Stack direction="row" spacing={2}>
              <WhiteButton onClick={() => useDialogState.setState({ orderVisible: true })}>
                <CoinIcon /> 充值
              </WhiteButton>
              <WhiteButton onClick={() => useDialogState.setState({ inviteVisible: true })}>
                邀请好友
              </WhiteButton>
              {vipInfo?.newAccount === true && (
                <WhiteButton onClick={() => useDialogState.setState({ noviceVisible: true })}>
                  领取新人礼包
                </WhiteButton>
              )}
            </Stack>
          </Box>
          <Tabs centered value={tabIndex} onChange={(_, i) => setTabIndex(i)}>
            <Tab label="使用记录" value={1}></Tab>
            <Tab label="收益记录" value={2}></Tab>
          </Tabs>
          {tabIndex === 1 && <UsageList uid={user!.id} />}
          {tabIndex === 2 && <IncomeList uid={user!.id} />}
        </Paper>
      </Container>
    </Box>
  )
}

const getConsumeReason = (type: number) => {
  if (type === 0) {
    return "聊天"
  } else if (type === 1) {
    return "搜索"
  } else if (type === 2) {
    return "图片生成"
  }
  return "积分消耗"
}

const UsageList: React.FC<{ uid: number }> = ({ uid }) => {
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [list, setList] = useState<RecordItemProps[]>([])
  useEffect(() => {
    getUsageList({ uid, pageNo: page, pageSize: 8 }).then((resp) => {
      if (resp.status === 200) {
        const { data, totalPages } = resp.data
        if (data) {
          const list = data.map((item) => {
            return {
              id: item.id,
              title: getConsumeReason(item.type),
              createTime: item.createTime,
              count: item.deduct ?? 1,
            }
          })
          setTotalPage(totalPages)
          setList(list)
        }
      } else {
        addToast(resp.statusText ?? "网络错误", "error")
      }
    })
  }, [page])
  return (
    <>
      <Box mx={3} borderBottom="1px solid #aaa4">
        {list.map((item) => (
          <RecordItem key={item.id} {...item} />
        ))}
      </Box>
      {totalPage > 1 && (
        <Pagination
          count={totalPage}
          onChange={(_, page) => setPage(page)}
          sx={{
            mt: 2,
            "& .MuiPagination-ul": {
              justifyContent: "center",
            },
          }}
        />
      )}
    </>
  )
}

const IncomeList: React.FC<{ uid: number }> = ({ uid }) => {
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [list, setList] = useState<RecordItemProps[]>([])
  useEffect(() => {
    getIncomList({ uid, pageNo: page, pageSize: 8, state: 1 }).then((resp) => {
      if (resp.status === 200) {
        const { data, pageNo, totalPages } = resp.data
        if (data) {
          const list = data.map((item) => {
            const sku: API.VipProduct = JSON.parse(item.vipJson)
            item.title = sku.title
            return {
              id: item.id,
              title: sku.title,
              createTime: item.createTime,
              count: item.gptUsesTimes,
            }
          })
          setTotalPage(totalPages)
          setList(list)
        }
      } else {
        addToast(resp.statusText ?? "网络错误", "error")
      }
    })
  }, [page])
  return (
    <>
      <Box mx={3} borderBottom="1px solid #aaa4">
        {list.map((item) => (
          <RecordItem key={item.id} {...item} />
        ))}
      </Box>
      <Pagination
        count={totalPage}
        onChange={(_, page) => setPage(page)}
        sx={{
          mt: 2,
          "& .MuiPagination-ul": {
            justifyContent: "center",
          },
        }}
      />
    </>
  )
}

type RecordItemProps = {
  id?: string | number
  title: string
  createTime: number
  count: number
}

const RecordItem: React.FC<RecordItemProps> = ({ title, createTime, count }) => {
  const time = dayjs(createTime).format("YYYY-MM-DD HH:mm")
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        py: 2,
        borderTop: "1px solid #aaa4",
      }}
    >
      <Box fontSize="small" color="text.secondary">
        {time}
      </Box>
      <Box flex={1} ml={1}>
        {title}
      </Box>
      <Box>{count}积分</Box>
    </Box>
  )
}

export default WalletPage
