import { useEffect, useRef, useState } from "react"

import { Box, Chip, ChipProps, InputBase, Stack, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import useDialogState from "@/state/dialog"
import useUserInfo from "@/state/user"
import { Message, createMessage } from "@/types"

import GradientButton from "../Button"

const FloatStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
}))

const GroupChip = styled(Chip)<ChipProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

function MessageInput(props: {
  onSubmit: (newMsg: Message, needGenerating?: boolean) => void
  messageInput: string
  setMessageInput: (value: string) => void
  tips?: string[]
}) {
  const { t } = useTranslation()
  const { messageInput, setMessageInput } = props

  const [showTips, setShowTips] = useState(false)
  const user = useUserInfo((state) => state.user)
  const isLogined = Boolean(user)

  const ref = useRef<HTMLElement>()
  useEffect(() => {
    ref.current?.blur()
  }, [])

  const submit = (needGenerating = true) => {
    if (!isLogined) {
      useDialogState.setState({ loginVisible: true })
      return
    }
    if (messageInput.length === 0) {
      return
    }
    props.onSubmit(createMessage("user", messageInput), needGenerating)
    setMessageInput("")
  }
  return (
    <FloatStack className="inputMessage" direction="column" spacing={1} sx={{ pl: 2, pr: 2 }}>
      {showTips && (
        <Box display="flex" flexDirection="row" flexWrap="wrap-reverse">
          {props.tips?.map((i) => (
            <GroupChip
              key={i}
              variant="outlined"
              clickable
              label={i}
              onClick={() => setMessageInput(i)}
            />
          ))}
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        bgcolor="background.paper"
        padding={0.4}
        borderRadius="28px"
        alignItems="flex-end"
        sx={{ border: "2px solid", borderColor: (theme) => theme.palette.primary.light }}
      >
        <InputBase
          multiline
          placeholder="提问"
          value={messageInput}
          inputRef={ref}
          sx={{ flex: 1, px: 2, py: 1 }}
          onChange={(event) => setMessageInput(event.target.value)}
          fullWidth
          maxRows={12}
          autoFocus
          id="message-input"
          onFocus={() => setShowTips(true)}
          onBlur={() => setTimeout(() => setShowTips(false), 300)}
          onKeyDown={(event) => {
            if (
              event.keyCode === 13 &&
              !event.shiftKey &&
              !event.ctrlKey &&
              !event.altKey &&
              !event.metaKey
            ) {
              event.preventDefault()
              submit()
              return
            }
            if (event.keyCode === 13 && event.ctrlKey) {
              event.preventDefault()
              submit(false)
              return
            }
          }}
        />
        <GradientButton
          type="submit"
          variant="contained"
          size="medium"
          sx={{ fontSize: "16px", padding: "10px 20px", height: "40px" }}
          onClick={() => submit()}
        >
          {isLogined ? t("send") : t("login")}
        </GradientButton>
      </Box>
      <Typography variant="caption" sx={{ opacity: 0.3, ml: 2, display: "none" }}>
        {t("[Enter] send, [Shift+Enter] line break, [Ctrl+Enter] send without generating")}
      </Typography>
    </FloatStack>
  )
}

export default MessageInput
