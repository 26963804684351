import axios from "axios";
import { StrapiApi } from "../types";

const baseURL = "https://www.qihuaitech.com/strapi";

const StrapiKey = "142aa68409acd6a91a318d9fe3701c7324d05bc64c40ca7ae6d3ec38cb5a3b7b99adb6258c545ad6c621c287d4fafb246a749019a22b2ad0e4a6777de5d2bf9fae1e4d9f2b411a73dd5451d0991c7432f5925b2a71c2efcfa2396ca018e8131d4f92e7335a2f92a4a4aa5d0b61c726b5ceac9aa89f493f95e2494473c63bd592"

const instance = axios.create({
    baseURL,
    timeout: 13000,
    validateStatus: function (status) {
        return status >= 200 && status < 500;
    },
    headers: {
        "Authorization": "Bearer " + StrapiKey,
    }
});

export async function getPromptList(tag: string | number) {
    return instance.get<StrapiApi.PaginateResp<StrapiApi.PromptType>>("/prompts", {
        params: {
            pagination: {
                page: 1,
                pageSize: 30,
            },
            sort: ['weight:desc', 'createAt']
        },
    })
}

export async function getStyleList(style: string) {
    return instance.get<StrapiApi.PaginateResp<StrapiApi.GraphStyle>>("/paint-styles", {
        params: {
            pagination: {
                page: 1,
                pageSize: 100,
            },
            populate: ['cover'],
            filters: {
                type: {
                    "$eq": style
                }
            },
            sort: ["weight:desc", "createdAt"]
        }
    }).then(res => {
        return res.data.data.map((i) => i.attributes)
    });
}


export async function getModelList() {
    return instance.get<StrapiApi.PaginateResp<StrapiApi.ImageModel>>("/models", {
        params: {
            pagination: {
                page: 1,
                pageSize: 10
            },
            populate: ['cover'],
            filters: {
                visible: {
                    "$eq": true
                }
            },
            sort: "weight:desc"
        }
    }).then(res => {
        return res.data.data.map((i) => i.attributes)
    })
}

export async function getLoraList() {
    return instance.get<StrapiApi.PaginateResp<StrapiApi.LoraModel>>("/lo-ras", {
        params: {
            pagination: {
                page: 1,
                pageSize: 20
            },
            populate: ['cover'],
            filters: {
                visible: {
                    "$eq": true
                }
            },
            sort: "weight:desc"
        }
    }).then(res => {
        return res.data.data.map((i) => i.attributes)
    }) 
}
