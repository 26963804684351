import { useEffect, useRef } from "react";


export default function useScroll() {
  const messageListRef = useRef<HTMLDivElement>(null)
  const messageAnchorRef = useRef<{ msgId: string; smooth?: boolean } | null>(null)

  useEffect(() => {
    if (document.body.scrollHeight > document.body.clientHeight) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth"
      });
      return
    }
    if (!messageAnchorRef.current) {
      return
    }
    if (!messageListRef.current) {
      return
    }
    const container = messageListRef.current
    const element = document.getElementById(messageAnchorRef.current.msgId)
    if (!container || !element) {
      return
    }
    const elementRect = element.getBoundingClientRect()
    const containerRect = container.getBoundingClientRect()
    const isInsideLeft = elementRect.left >= containerRect.left
    const isInsideRight = elementRect.right <= containerRect.right
    const isInsideTop = elementRect.top >= containerRect.top
    const isInsideBottom = elementRect.bottom <= containerRect.bottom
    if (isInsideLeft && isInsideRight && isInsideTop && isInsideBottom) {
      return
    }
    // 平滑滚动
    element.scrollIntoView({
      behavior: messageAnchorRef.current.smooth ? "smooth" : "auto",
      block: "end",
      inline: "nearest",
    })
  })
  // 切换到当前会话，自动滚动到最后一条消息
  useEffect(() => {
    if (!messageListRef.current) {
      return
    }
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight
  }, [messageListRef])

  return { messageListRef, messageAnchorRef }
}