export { default as useUserInfo } from './user'
export * from './user'

export { useSession } from './session'
export { useDialogState } from "./dialog"

import { enqueueSnackbar } from 'notistack'

type ToastLevel = "success" | "info" | "warning" | "error"

export const addToast = (message: string, level: ToastLevel) => {
    enqueueSnackbar(message, {
        variant: level
    })
}
