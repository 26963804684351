import React, { useEffect, useState } from "react"

import { Masonry } from "@mui/lab"
import {
  Box,
  Card,
  CardContent,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { BiSearch } from "react-icons/bi"
import { shallow } from "zustand/shallow"

import promptList from "@/config/prompt.json"
import useDebounce from "@/hooks/useDebounce"
import useSession from "@/state/session"
import { Prompt, defaultConfig } from "@/types"
import { useSettingsState } from "../SettingWindow"

// promots from https://www.aishort.top/
const categroyObj: Record<string, string> = {
  favorite: "常用",
  language: "语言/翻译",
  write: "写作",
  article: "文章",
  comments: "点评",
  text: "文本处理",
  life: "生活",
  interesting: "趣味",
  living: "居家",
  mind: "思维",
  social: "社交",
  pedagogy: "教育",
  tool: "工具",
  doctor: "医生",
  professional: "职业",
  academic: "学术",
  music: "音乐",
  company: "公司",
  philosophy: "哲学",
  contribute: "其他",
}

let tags = new Set<String>()

promptList.forEach((e) => {
  e.tags.forEach((tag) => {
    tags.add(tag)
  })
})

const commonPromptList = promptList.filter((i) => i.tags.includes("favorite"))

const TabBox = styled(Box)({
  flexShrink: 1,
  width: "100%",
  overflowX: "auto",
  overflowY: "hidden",
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  "::-webkit-scrollbar": { display: "none" },
})

const PromptGallery: React.FC = () => {
  const [tag, setTag] = useState<string>("favorite")
  const [list, setList] = useState(commonPromptList)
  const [keyword, setKeyword] = useState<string>()
  const searchKey = useDebounce(keyword, 300)
  const [currentSession, updateChatSession] = useSession(
    (state) => [state.currentSession, state.updateChatSession],
    shallow,
  )
  useEffect(() => {
    if (!searchKey || !Boolean(searchKey?.trim())) {
      setList(commonPromptList)
      return
    } else {
      const result = promptList.filter((i) => {
        return i.desc_cn.includes(searchKey) || i.remark.includes(searchKey)
      })
      setList(result)
    }
  }, [searchKey])
  const handleSelectChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string
    if (value) {
      setTag(value)
    }
    const result = promptList.filter((i) => i.tags.includes(value))
    setList(result)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value
    setKeyword(keyword)
  }

  const handleCreateNewSession = (item?: Prompt) => {
    const prompt = item ?? defaultConfig.prompt
    currentSession.name = item?.title ?? "新对话"
    currentSession.prompt = prompt
    currentSession.inital = false
    const settings = useSettingsState.getState()
    // todo 加上提示词中的配置
    currentSession.config = {
      ...settings,
    }
    updateChatSession(currentSession)
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        pl: 1,
        justifyContent: "stretch",
        justifySelf: "flex-end",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          background:
            "url(https://vfile-conf.oss-cn-shanghai.aliyuncs.com/img/Cyberpunk__technology__robot__network__masterpiece__high_quality__8k___S2218660000_St25_G7.5.jpeg)",
          backgroundSize: "100%, 180px",
          height: 130,
          display: "flex",
          alignItems: "end",
        }}
      >
        <Box
          sx={{ background: "linear-gradient(to bottom,transparent, #222a)", width: "100%", p: 1 }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "white",
            }}
          >
            超多场景等你发现 😎😎✨
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          pr: 1,
          py: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {/* <InputBase placeholder="搜索场景" /> */}
        <OutlinedInput
          size="small"
          placeholder="搜索场景"
          onChange={handleSearch}
          endAdornment={<BiSearch fontSize="medium" />}
        />
        <Select size="small" value={tag} onChange={handleSelectChange} sx={{ minWidth: 120 }}>
          {Object.entries(categroyObj).map((item) => (
            <MenuItem value={item[0]}>{item[1]}</MenuItem>
          ))}
        </Select>
      </Box>

      <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={1.5}>
        {list.map((item) => (
          <Card elevation={1}>
            <CardContent onClick={() => handleCreateNewSession(item)}>
              <Box fontSize="medium" fontWeight="bold">
                {item.title}
              </Box>
              <Box
                sx={{
                  marginTop: 1,
                  fontSize: "small",
                  display: "--webkit-box",
                  "-webkit-line-clamp": "3",
                  overflowY: "hidden",
                }}
              >
                {item.remark}
              </Box>
            </CardContent>
          </Card>
        ))}
      </Masonry>
    </Box>
  )
}

export default PromptGallery
