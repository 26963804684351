import React from "react"

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Slider,
  SliderValueLabelProps,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { BiX } from "react-icons/bi"
import { shallow } from "zustand/shallow"

import CoinIcon from "../images/CoinIcon"
import useDialogState from "../state/dialog"
import useSession from "../state/session"
import { Prompt, SessionConfig } from "../types"
import FormItem from "./FormItem"
import Switcher from "./Switcher"

function ValueLabelComponent(props: SliderValueLabelProps) {
  const { children, value } = props

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  )
}

type SessionSettingsDialogProps = {
  prompt: Prompt
  config: SessionConfig
}

const SessionSettingsDialog: React.FC<SessionSettingsDialogProps> = ({ prompt, config }) => {
  const visible = useDialogState((state) => state.sessionSettingVisible)

  const { t, i18n } = useTranslation()
  const onCancel = () => {
    useDialogState.setState({ sessionSettingVisible: false })
  }
  return (
    <Dialog open={visible} onClose={onCancel} fullWidth>
      <DialogTitle>
        当前会话设置
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <BiX />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <SessionConfigSection config={config} />
      </DialogContent>
    </Dialog>
  )
}

const PromptConfigSection = () => {
  return <></>
}

const SessionConfigSection: React.FC<{ config: SessionConfig }> = ({ config }) => {
  const [currentSession, updateChatSession] = useSession(
    (state) => [state.currentSession, state.updateChatSession],
    shallow,
  )
  const changeConfig = (newConfig: Partial<SessionConfig>) => {
    currentSession.config = {
      ...config,
      ...newConfig,
    }
    updateChatSession(currentSession)
  }
  return (
    <>
      <FormItem label="模型">
        <Select label="language" size="small" id="language-select" value="gpt-3.5-turbo" disabled>
          <MenuItem key="GPT3.5" value="gpt-3.5-turbo">
            GPT3.5
          </MenuItem>
          <MenuItem key="GPT4" value="gpt-4">
            GPT4
          </MenuItem>
        </Select>
      </FormItem>

      <FormItem label="关联消息" tip="支持上下文联系的最多消息条数">
        <Slider
          valueLabelDisplay="auto"
          slots={{
            valueLabel: ValueLabelComponent,
          }}
          sx={{ width: "120px" }}
          aria-label="custom thumb label"
          defaultValue={config.maxMsgLength}
          onChangeCommitted={(_e, maxMsgLength) =>
            changeConfig({ maxMsgLength: maxMsgLength as number })
          }
          min={0}
          max={20}
        />
      </FormItem>
      <FormItem label="风格">
        <ToggleButtonGroup
          color="primary"
          value={config.temperature}
          exclusive
          aria-label="Platform"
          onChange={(_e, temperature) => changeConfig({ temperature })}
        >
          <ToggleButton value={1.5}>创造力</ToggleButton>
          <ToggleButton value={0.8}>均衡</ToggleButton>
          <ToggleButton value={0}>精确</ToggleButton>
        </ToggleButtonGroup>
      </FormItem>
      <FormItem label="最大回复长度" tip="AI能回复的最大长度">
        <Slider
          valueLabelDisplay="auto"
          sx={{ width: "120px" }}
          aria-label="custom thumb label"
          defaultValue={config.maxTokens}
          onChangeCommitted={(_e, maxTokens) => changeConfig({ maxTokens: maxTokens as number })}
          min={500}
          max={4000}
        />
      </FormItem>
      <FormItem
        label="搜索"
        vipIcon={
          <Box fontSize="small" display="flex" alignItems="center" component="span">
            <CoinIcon /> <Box color="#a16207">10</Box>
          </Box>
        }
        tip="结合搜索引擎的搜索结果，回答更全面。每次使用消耗10积分。"
      >
        <Switcher checked={config.search} onChange={(_e, search) => changeConfig({ search })} />
      </FormItem>
      <FormItem
        label="联想搜索"
        vipIcon={
          <Box fontSize="small" display="flex" alignItems="center" component="span">
          <CoinIcon /> <Box color="#a16207">1</Box>
        </Box>
        }
        tip="智能生成相关联的提问。每次使用消耗1积分。"
      >
        <Switcher checked={config.suggest} onChange={(_e, suggest) => changeConfig({ suggest })} />
      </FormItem>
    </>
  )
}

export default SessionSettingsDialog
