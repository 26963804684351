import React from "react"

import { SnackbarProvider } from "notistack"
import ReactGA from "react-ga4"
import { RouterProvider, createBrowserRouter, useLocation } from "react-router-dom"

import {
  InviteDialog,
  LoginDialog,
  NoviceDialog,
  PaymentFormDialog,
  SettingWindow,
  SocialMediaDialog,
} from "@/components"

import { LandingPage, MainPage, SharePage, WalletPage } from "./pages"
import { weixin } from "./service"
import "./styles/App.scss"
import { ThemeSwitcherProvider } from "./theme/ThemeSwitcher"
import { isWechat } from "./util/env"

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageWithGa page={MainPage} title={"主页"} />,
  },
  {
    path: "/landing",
    element: <PageWithGa page={LandingPage} title={"宣传页"} />,
  },
  {
    path: "/wallet",
    element: <PageWithGa page={WalletPage} title={"价格"} />,
  },
  {
    path: "/share/:id",
    element: <PageWithGa page={SharePage} title={"分享"} />,
  },
  {
    path: "*",
    element: <PageWithGa page={MainPage} title={"主页"} />,
  },
])

type PageGaProps = {
  page: () => JSX.Element
  title: string
}

function PageWithGa({ page, title }: PageGaProps) {
  let location = useLocation()

  React.useEffect(() => {
    if (isWechat()) {
      weixin.configWX(window.location.href)
    }
    // Google Analytics
    ReactGA.send({ hitType: "pageview", page: location.pathname, title })
  }, [location])
  return React.createElement(page, { key: title })
}

export default function App() {
  return (
    <ThemeSwitcherProvider>
      <SnackbarProvider>
        <RouterProvider router={router} />
      </SnackbarProvider>
      <LoginDialog />
      <SocialMediaDialog />
      <PaymentFormDialog />
      <InviteDialog />
      <SettingWindow />
      <NoviceDialog />
    </ThemeSwitcherProvider>
  )
}
