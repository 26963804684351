import { v4 as uuidv4 } from 'uuid';
import { ThemeMode } from '../theme';
import { OpenAIMessage, OpenAIRoleEnumType, OpenAIRoleEnum } from './base';

export * from "./base"
export * as API from "./server";
export * as StrapiApi from './strapi'

export type Message = OpenAIMessage & {
    id: string;
    cancel?: () => void;
    generating?: boolean;
    waiting?: boolean;
    model?: string
    error?: boolean
}

export type Prompt = {
    title: string;
    // 英文提示词
    description: string;
    // 中文提示词
    desc_cn: string;
    remark: string;
    title_en: string;
    desc_en: string;
    remark_en: string;
    website?: string;
    tags: string[];
    id: number;
    weight: number;
}

export type SessionConfig = {
    model: string;
    maxMsgLength: number;
    temperature: number;
    maxTokens: number;
    search: boolean;
    suggest: boolean;
}

export interface Settings extends SessionConfig {
    maxContextSize: number
    theme: ThemeMode
    language: string
    fontSize: number
}

export interface Session {
    id: string
    name: string
    messages: Message[]
    generatedName?: string
    tips?: string[];
    prompt: Prompt;
    config: SessionConfig;
    inital?: boolean;
}

export function createMessage(role: OpenAIRoleEnumType = OpenAIRoleEnum.User, content: string = '', generating?: boolean, waiting?: boolean): Message {
    return {
        id: uuidv4(),
        content: content,
        role: role,
        generating,
        waiting
    }
}

export function createSession(name: string = "Untitled"): Session {
    return {
        id: uuidv4(),
        name: name,
        messages: [],
        prompt: defaultPrompt,
        config: session,
        inital: true
    }
}

const defaultPrompt: Prompt = {
    title: "无设定",
    description: "",
    desc_cn: "文生万物智能助理：你可以问我任何问题，也可以给我任何任务。",
    remark: "",
    title_en: "",
    desc_en: "",
    remark_en: "",
    tags: [],
    id: -1,
    weight: 0
}

const session: SessionConfig = {
    model: "gpt-3.5-turbo",
    maxMsgLength: 5,
    temperature: 0.8,
    maxTokens: 2000,
    search: false,
    suggest: false,
}

const settings: Settings = {
    ...session,
    maxContextSize: 1200,
    theme: ThemeMode.Dark,
    language: "zh-Hans",
    fontSize: 13
}

export const defaultConfig = {
    session,
    settings,
    prompt: defaultPrompt,
}