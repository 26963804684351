import React, { useCallback, useEffect, useRef, useState } from "react"

import { Box, CircularProgress, IconButton, InputBase } from "@mui/material"
import { BiImageAdd, BiSliderAlt } from "react-icons/bi"
import { shallow } from "zustand/shallow"

import { client } from "@/service"
import { addToast } from "@/state"

import GradientButton from "../Button"
import { handleImageFile, useAdvanceConfigState } from "./state"

type MessageInputProps = {}

const MessageInput: React.FC<MessageInputProps> = () => {
  const [messageInput, setMessageInput] = React.useState<string>("")
  const [rendering, setRendering] = useState(false)
  const [prompt, initImage] = useAdvanceConfigState(
    (state) => [state.prompt, state.initImage],
    shallow,
  )

  const ref = useRef<HTMLElement>()
  const inputElement = useRef<any>()

  useEffect(() => {
    ref.current?.blur()
  }, [])

  const startRender = useCallback(async () => {
    const { prompt: originPrompt, initImage, ration } = useAdvanceConfigState.getState()

    if (originPrompt.length > 0) {
      setRendering(true)
      const scale = localStorage.getItem("scale") !== null ? 2 : 1
      try {
        const msg = await client.renderImage(originPrompt, {
          initImage,
          useRecommendPrompt: true,
          width: ration.width * scale,
          height: ration.height * scale,
        })
        setMessageInput("")
        useAdvanceConfigState.setState({ prompt: "", currentTask: msg.requestId })
      } catch (error) {
        addToast("绘制出错", "error")
      }
      setRendering(false)
    } else {
      addToast("请输入图片的画面描述", "warning")
    }
  }, [messageInput])

  const chooseImage = () => {
    inputElement.current?.click()
  }

  const onFileChange = async (event?: React.ChangeEvent<HTMLInputElement>) => {
    try {
      handleImageFile(event?.target?.files)
    } catch (error: unknown) {
      if (error instanceof Error) return addToast(error.message, "warning")
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-end"
      className="inputMessage"
      sx={{
        m: 2,
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        p: 0.4,
        border: "2px solid",
        borderColor: (theme) => theme.palette.primary.light,
        borderRadius: "24px",
        bgcolor: "background.paper",
        justifyContent: "stretch",
      }}
    >
      {initImage && (
        <img
          className="inputBarImage"
          src={initImage}
          onClick={() =>
            useAdvanceConfigState.setState({
              initImage: undefined,
            })
          }
        />
      )}
      <input
        type="file"
        id="image-file-selector"
        hidden
        accept="image/*"
        ref={inputElement}
        onChange={onFileChange}
      />
      <IconButton onClick={chooseImage} sx={{ display: initImage ? "none" : "inherit" }}>
        <BiImageAdd />
      </IconButton>
      <InputBase
        multiline
        placeholder="输入画面描述"
        value={prompt}
        inputRef={ref}
        sx={{ flex: 1, px: 1, py: 1 }}
        inputProps={{ maxLength: 200 }}
        onChange={(event) =>
          useAdvanceConfigState.setState({
            prompt: event.target.value,
          })
        }
        fullWidth
        maxRows={6}
        autoFocus
        id="message-input"
      />
      <IconButton onClick={() => useAdvanceConfigState.setState({ configVisible: true })}>
        <BiSliderAlt />
      </IconButton>
      <GradientButton sx={{ height: 39 }} disabled={rendering} onClick={startRender}>
        {rendering && (
          <CircularProgress
            size={24}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
        绘图
      </GradientButton>
    </Box>
  )
}

export default MessageInput
