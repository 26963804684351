import { Box, Link } from "@mui/material"

function Footer() {
  return (
    <Box
      sx={{ my: 2, display: "flex", flexDirection: "column", alignItems: "center" }}
      component="footer"
    >
      <Box color="gray"> Copyright © 2020-2023 齐淮科技</Box>
      <Link color="#888" fontSize="small" href="http://beian.miit.gov.cn/">
        苏ICP备2021023926号
      </Link>
    </Box>
  )
}

export default Footer
