import { create } from "zustand";
import { GraphStyle, ImageModel, LoraModel } from "@/types/strapi"

export type RationProps = {
  name: string
  width: number
  height: number
  value: number
}

export type RationType = 0 | 1 | 2

export const defaultRation = {
  name: "1:1",
  value: 1,
  width: 640,
  height: 640,
}

export const rationOptions: RationProps[][] = [
  [defaultRation],
  [
    {
      name: "3:4",
      value: 0.75,
      width: 448,
      height: 640,
    },
    {
      name: "2:3",
      value: 0.66,
      width: 512,
      height: 768,
    },
    {
      name: "9:16",
      value: 0.56,
      width: 512,
      height: 896,
    },
    {
      name: "1:2",
      value: 0.5,
      width: 384,
      height: 768,
    },
  ],

  [
    {
      name: "4:3",
      value: 1.33,
      width: 640,
      height: 448,
    },
    {
      name: "3:2",
      value: 1.5,
      width: 768,
      height: 512,
    },
    {
      name: "16:9",
      value: 1.778,
      width: 896,
      height: 512,
    },
    {
      name: "2:1",
      value: 2,
      width: 768,
      height: 384,
    },
  ],
]

type AdvanceConfigStateType = {
  configVisible: boolean
  prompt: string
  model: Omit<ImageModel, "cover">,
  useRecommendPrompt: boolean
  currentTask?: string
  rationType: RationType
  ration: RationProps
  styles: Omit<GraphStyle, 'cover'>[]
  lora?: Omit<LoraModel, "cover">,
  options: RationProps[]
  initImage?: string
  numOutputs: number
  changeType: (type: RationType) => void
  includes: (style: GraphStyle) => boolean
  toggleStyles: (style: GraphStyle) => void
}

export const useAdvanceConfigState = create<AdvanceConfigStateType>((set, get) => ({
  configVisible: false,
  prompt: "",
  model: { key: "base", title: "通用", id: 1 },
  useRecommendPrompt: true,
  rationType: 0,
  numOutputs: 1,
  ration: defaultRation,
  styles: [],
  options: rationOptions[0],
  changeType: (type: RationType) => {
    if (get().rationType !== type) {
      const options = rationOptions[type]
      const ration = options[0]
      set({
        rationType: type,
        ration,
        options,
      })
    }
  },
  includes: (style: GraphStyle) => {
    const styles = get().styles
    const index = styles.findIndex((item, _) => item.key === style.key)
    return index >= 0
  },
  toggleStyles: (style: GraphStyle) => {
    const styles = get().styles
    const index = styles.findIndex((item, _) => item.key === style.key)
    console.log("toggle style", style, index)

    if (index === -1) {
      set({
        styles: [...styles, style],
      })
    } else {
      styles.splice(index, 1)
      set({
        styles: [...styles],
      })
    }
  },
}))

function calculateImageSize(width: number, height: number) {
  const maxSide = Math.max(width, height)
  let scale = 1
  if (maxSide > 1024) {
    scale = 1024 / maxSide
  }
  let w = width * scale
  let h = height * scale
  w = w - w % 64
  h = h - h % 64
  console.log("image size", w, h);

  return {
    width: w,
    height: h,
  }
}

export function handleImageFile(files?: FileList | null) {
  if (files && files.length) {
    const file = files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    if (file.size > 3 * 1024 * 1024) {
      throw new Error("文件大小不得超过3M")
    }
    let img = new Image()
    img.src = URL.createObjectURL(file)
    img.onload = () => {
      console.log(" on image load size", img.width, img.height);
      const { width, height } = calculateImageSize(img.width, img.height)
      useAdvanceConfigState.setState({
        ration: {
          name: "custom",
          width,
          height,
          value: width / height,
        },
      })
    }
    reader.onloadend = function () {
      const base64String = reader.result
      if (typeof base64String === "string") {
        useAdvanceConfigState.setState({
          initImage: base64String,
        })
      }
    }
  }
}