import { Message } from '../types';


/** 命名会话语句 */
export function nameConversation(msgs: Message[]): string {
    const format = (msgs: string[]) => msgs.map(msg => msg).join('\n\n---------\n\n')
    const prompt = `Name the conversation based on the chat records.
Please provide a concise name, within 10 characters and without quotation marks.
Please use Chinese.
You only need to answer with the name.
The following is the conversation:

\`\`\`
${format(msgs.map(msg => msg.content))}
\`\`\`

The conversation is named:`
    return prompt
}

// I give you a question, you give me 3 questions in the related field, no explanation, answer in Chinese. For example, my question: "Where is the longest river in the world"? Your answer is: "3. Where is the highest mountain in the world? 2. What equipment do I need to go surfing inside a river? 1. where is the deepest sea in the world", here are my questions:

/** 生成提示词语句 */
export function createTips(msg: Message): string {
    return `你的任务是为问题生成3个相关领域相关问题。返回列表的形式，用逗号隔开。
    Example:
    问题：
    世界上最长的河流在哪里？
    回答：
    世界上最高的山在哪里？,去河流里面冲浪需要什么装备?,世界上最深的海在哪里？

    Query: \`\`\`
     ${msg.content} 
    \`\`\` 
    `
}


export const defaultPrompt = {
    description: "",
    desc_cn: "文生万物智能助理：你可以问我任何问题，也可以给我任何任务。",
}