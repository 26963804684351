import { create } from "zustand";

type DialogState = {
    loginVisible: boolean,
    settingVisible: boolean,
    sessionSettingVisible: boolean,
    shareVisible: boolean,
    deleteVisible: boolean,
    renameVisible: boolean,
    // 订单下单界面
    orderVisible: boolean,
    // 用户菜单弹窗
    profileVisible: boolean, 
    // 交流群弹窗
    socialMediaVisible: boolean, 
    // 新人优惠券弹窗
    noviceVisible: boolean, 
    // 邀请好友界面
    inviteVisible: boolean,
}

export const useDialogState = create<DialogState>((set) => ({
    loginVisible: false,
    settingVisible: false,
    sessionSettingVisible: false,
    shareVisible: false,
    deleteVisible: false,
    renameVisible: false,
    orderVisible: false,
    profileVisible: false,
    socialMediaVisible: false,
    noviceVisible: false,
    inviteVisible: false,
}))

export const closeAll = () => {
    useDialogState.setState({}, true)
}

export default useDialogState