
const chars: Array<string> = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z"
];

export function base62To10(code: string) {
    let id = 0;
    let L = code.length;

    for (let i = 0; i < L; i++) {
        id += chars.indexOf(code.charAt(i)) * Math.pow(62, i);
    }
    return id;
}

export function base10To62(id: number): string {
    let code: string = ""
    while (id !== 0) {
        code += chars[id % 62]
        id = Math.floor(id / 62)
    }
    return code;
}