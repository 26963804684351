import { SvgIcon, SvgIconProps } from "@mui/material"

export default function CoinIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 1024 1024">
      <path
        d="M512 896c200.298667 0 362.666667-152.810667 362.666667-341.333333s19.050667-192.533333-362.666667-192.533334S149.333333 366.144 149.333333 554.666667s162.368 341.333333 362.666667 341.333333z"
        fill="#FF5C00"
        p-id="11504"
      ></path>
      <path
        d="M149.333333 490.666667a362.666667 341.333333 0 1 0 725.333334 0 362.666667 341.333333 0 1 0-725.333334 0Z"
        fill="#FFCC00"
        p-id="11505"
      ></path>
      <path
        d="M820.224 310.698667a332.288 332.288 0 0 1 31.146667 59.349333L404.629333 816.789333a373.12 373.12 0 0 1-64.853333-25.642666z m-189.269333-142.570667c57.621333 18.816 108.629333 50.922667 148.906666 92.416L284.16 756.266667c-46.570667-35.456-83.626667-81.514667-107.008-134.314667z"
        fill="#FFE3B6"
        p-id="11506"
      ></path>
      <path
        d="M192 480a320 288 0 1 0 640 0 320 288 0 1 0-640 0Z"
        fill="#FF7325"
        p-id="11507"
      ></path>
      <path
        d="M213.333333 480a298.666667 266.666667 0 1 0 597.333334 0 298.666667 266.666667 0 1 0-597.333334 0Z"
        fill="#FFB329"
        p-id="11508"
      ></path>
      <path
        d="M808.533333 512c-17.706667 132.181333-143.722667 234.666667-296.533333 234.666667s-278.826667-102.485333-296.533333-234.666667z"
        fill="#FF9B1A"
        p-id="11509"
      ></path>
      <path
        d="M512 213.333333c108.074667 0 202.730667 51.242667 255.168 128H256.853333c52.437333-76.757333 147.093333-128 255.168-128z"
        fill="#FFCC00"
        p-id="11510"
      ></path>
      <path
        d="M587.648 510.037333l94.72-13.930666 0.298667 40.938666c-6.4 25.045333-16.426667 45.973333-30.165334 62.762667a129.92 129.92 0 0 1-51.093333 38.037333c-20.352 8.533333-46.250667 12.821333-77.674667 12.821334-38.144 0-69.290667-5.205333-93.482666-15.637334-24.170667-10.432-45.034667-28.757333-62.592-55.018666-17.557333-26.24-26.325333-59.84-26.325334-100.8 0-54.613333 15.445333-96.554667 46.314667-125.888 30.890667-29.333333 74.56-43.989333 131.050667-43.989334 44.202667 0 78.954667 8.405333 104.256 25.194667 9.962667 6.613333 28.501333 18.346667 55.594666 35.2-0.341333 16.469333-0.085333 30.549333 0.768 42.197333l-95.744 20.053334c-3.349333-10.069333-6.869333-17.408-10.538666-22.058667a61.482667 61.482667 0 0 0-22.250667-18.005333 67.328 67.328 0 0 0-29.44-6.314667c-24.576 0-43.413333 9.301333-56.490667 27.904-9.898667 13.802667-14.848 35.477333-14.848 65.024 0 36.608 5.909333 61.696 17.706667 75.264 11.818667 13.589333 28.416 20.373333 49.792 20.373333 20.736 0 36.416-5.482667 47.04-16.426666 10.602667-10.944 18.304-26.858667 23.104-47.701334z"
        fill="#FF5C00"
        p-id="11511"
      ></path>
      <path
        d="M587.648 467.370667L682.666667 494.378667c-6.4 25.045333-16.426667 45.973333-30.165334 62.762666a129.92 129.92 0 0 1-51.093333 38.037334c-20.352 8.533333-46.250667 12.821333-77.674667 12.821333-38.144 0-69.290667-5.205333-93.482666-15.637333-24.170667-10.432-45.034667-28.757333-62.592-55.018667-17.557333-26.24-26.325333-59.84-26.325334-100.8 0-54.613333 15.445333-96.554667 46.314667-125.888 30.890667-29.333333 74.56-43.989333 131.050667-43.989333 44.202667 0 78.954667 8.405333 104.256 25.194666 25.28 16.810667 44.074667 42.602667 56.362666 77.397334l-95.744 20.053333c-3.349333-10.069333-6.869333-17.408-10.538666-22.058667a61.482667 61.482667 0 0 0-22.250667-18.005333 67.328 67.328 0 0 0-29.44-6.314667c-24.576 0-43.413333 9.301333-56.490667 27.904-9.898667 13.802667-14.848 35.477333-14.848 65.024 0 36.608 5.909333 61.696 17.706667 75.264 11.818667 13.589333 28.416 20.373333 49.792 20.373334 20.736 0 36.416-5.482667 47.04-16.426667 10.602667-10.944 18.304-26.858667 23.104-47.701333z"
        fill="#FFDC96"
        p-id="11512"
      ></path>
      <path
        d="M622.933333 291.861333c18.858667 12.522667 34.112 30.058667 45.738667 52.586667l-34.24 34.197333-50.858667 10.666667c-3.349333-10.069333-6.869333-17.408-10.538666-22.058667a61.482667 61.482667 0 0 0-22.250667-18.005333 67.328 67.328 0 0 0-29.44-6.314667c-24.576 0-43.413333 9.301333-56.490667 27.904-9.898667 13.802667-14.848 35.477333-14.848 65.024 0 36.608 5.909333 61.696 17.706667 75.264 5.525333 6.357333 12.074667 11.221333 19.690667 14.592l-63.637334 63.616c-21.44-10.794667-40.149333-28.117333-56.106666-51.989333-9.92-14.848-17.045333-32.021333-21.333334-51.584l215.893334-215.893333c24.170667 3.84 44.416 11.178667 60.736 21.994666z"
        fill="#FFECBD"
        p-id="11513"
      ></path>
    </SvgIcon>
  )
}
