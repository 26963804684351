import { addToast, useSession, useUserInfo } from "@/state";
import { ImageMessage, RenderParams, createRenderParams } from "@/types/image";
import { ImageResult, ImageStep } from "@/types/server";
import { createParser } from "eventsource-parser";

import { useSettingsState } from "../components/SettingWindow";
import * as prompts from "../config/prompts";
import { Message, OpenAIMessage, OpenAIRoleEnum, Session, defaultConfig } from "../types";
import { API } from "../types";
import * as api from "./api";
import { reduceCoins } from "./coins";


export interface OnTextCallbackResult {
  // response content
  text: string
  // cancel for fetch
  cancel: () => void
}

/** 生成会话名称 */
export async function generateName(msgs: Message[]): Promise<string | undefined> {
  const vipLevel = useUserInfo.getState().vip?.member ?? 0
  if (vipLevel === 0) {
    return
  }
  const userMsgs = msgs.filter((i) => i.role === "user")
  if (userMsgs.length < 1) {
    return
  }
  const prompt = (msgs.map(msg => msg.content)).join()

  const resp = await api.doAiAction(prompt, "title_generate")
  if (resp.status === 200) {
    const content = resp.data.msg
    console.log("openai completion", content)
    return content
  }
}

/** 生成问题提示 */
export async function generateTips(msgs: Message[]): Promise<string[]> {
  const vipLevel = useUserInfo.getState().vip?.member
  if (vipLevel === 0) {
    return []
  }
  const userMsgs = msgs.filter((i) => i.role === "user").reverse()
  if (userMsgs.length < 1) {
    return []
  }
  const prompt = prompts.createTips(userMsgs[0])
  const resp = await api.postSinglePrompt(prompt)
  if (resp.status === 200 && resp.data.state === 200) {
    const obj: API.Completion = JSON.parse(resp.data.msg)
    let choices = obj.choices[0].message.content.split(",")
    // 简单处理一下
    choices = choices.filter((i) => i.length > 5).map((i) => i.trim())
    if (choices.length > 3) {
      return choices.slice(-3)
    } else {
      return choices
    }
  }
  return []
}

export async function genrateImagePropmt(message: string) {
  const resp = await api.doAiAction(message, "sd_prompt")
  if (resp.status === 200) {
    return resp.data.msg
  } else {
    return null
  }
}

/**
 * 按长度切割当前会话的所有消息
 * @param msgs 当前消息
 * @param maxLength 最大的消息长度
 * @param maxContextSize 最大累计的消息内容长度
 * @returns openAi接受的消息格式
 */
async function sliceMessage(
  msgs: Message[],
  systemMessage: OpenAIMessage,
  maxLength: number,
  maxContextSize: number,
) {
  /// 系统设定的提示词长度大于2才用
  const head = systemMessage.content ? systemMessage : null
  // lazy load
  const wordCount = await import("../util/tokens")
  const maxLen = Number(maxContextSize)
  let headLen = head ? wordCount.estimateTokens(head.content) : 0

  let prompts: Message[] = []
  for (let i = msgs.length - 1; i >= msgs.length - maxLength - 1; i--) {
    const msg = msgs[i]
    if (!msg) {
      continue
    }
    const msgTokenSize: number = wordCount.estimateTokens(msg.content) + 200 // 200 作为预估的误差补偿
    if (msgTokenSize + headLen > maxLen) {
      break
    }
    //去除空消息, 错误消息
    if (!msg.content.trim() || msg.generating === true || msg.error) {
      continue
    }
    prompts.unshift(msg)
    headLen += msgTokenSize
  }

  let msgList = prompts.map((msg) => ({
    role: msg.role,
    content: msg.content,
  }))
  if (head) {
    msgList.unshift(head)
  }
  return { msgList, headLen }
}

/** 计算token限制 */
const calculateTokenLimit = (userMaxToken: number) => {
  const maxContextSize = 3800
  const maxTokens = Math.min(4000, userMaxToken)
  return { maxContextSize, maxTokens }
}

/** 调用并回复消息 */
export async function replay(
  session: Session,
  promptMsgs: Message[],
  onText?: (option: OnTextCallbackResult) => void,
  onError?: (error: AppError) => void,
) {
  const config = session.config ?? defaultConfig.settings

  const settings = useSettingsState.getState()
  const { model } = settings

  if (promptMsgs.length === 0) {
    throw new Error("No messages to replay")
  }
  // 根据用户配置和会员等级计算最大token长度
  const { maxContextSize, maxTokens: maxTokensNumber } = calculateTokenLimit(config.maxTokens)

  // fetch has been canceled
  let hasCancel = false
  // abort signal for fetch

  const controller = new AbortController()
  const cancel = () => {
    hasCancel = true
    controller.abort()
  }

  let fullText = ""
  try {
    const systemPrompt: OpenAIMessage = {
      role: OpenAIRoleEnum.System,
      content: session.prompt.description,
    }
    // 根据条件限制选择最近的消息
    const { msgList: messages, headLen } = await sliceMessage(
      promptMsgs,
      systemPrompt,
      config.maxMsgLength,
      maxContextSize,
    )
    const user = String(useUserInfo.getState().user?.id)
    const max_tokens = Math.min(maxContextSize - headLen, maxTokensNumber)
    console.log(messages, headLen, max_tokens)

    // 发送API请求
    const response = await api.postPrompts(
      {
        messages,
        model,
        max_tokens,
        user,
        stream: true,
        temperature: config.temperature,
        search: config.search === true,
      },
      controller,
    )
    const error = await filterError(response)
    if (error && onError) {
      onError(error)
      return
    }
    await handleSSE(response, (message) => {
      if (message === "[DONE]") {
        return
      }

      const data = JSON.parse(message)
      if (data.error) {
        throw new Error(`Error from OpenAI: ${JSON.stringify(data)}`)
      }
      const text = data.choices[0]?.delta?.content
      if (text !== undefined) {
        fullText += text
        if (onText) {
          onText({ text: fullText, cancel })
        }
      }
    })

    // 消耗一次使用情况
    reduceCoins(config.search === true ? 10 : 1, true)
  } catch (error: any) {
    console.warn("ai接口出错", error.message)
    if (hasCancel) {
      return
    }
    if (onError) {
      console.log("on error:", error)
      let err: AppError = { msg: error.message, code: -1 }
      if (error.message?.includes("Failed to fetch")) {
        err.msg = "网络错误, 请检查网络"
      }
      onError(err)
    }
  }
  return fullText
}

type AppError = {
  code?: number,
  msg: string,
}

type PromptConfig = {
  useRecommendPrompt: boolean
} & Partial<RenderParams>

/** 渲染图片 */
export async function renderImage(originPrompt: string, config: PromptConfig = { useRecommendPrompt: true }) {
  const userId = useUserInfo.getState().user?.id

  let prompt = originPrompt
  const regex = /[\u4e00-\u9fa5]/ //中文字符
  const needTranslate = regex.test(originPrompt)
  if (needTranslate) {
    // 需要翻译
    try {
      prompt = await api.translate(originPrompt)
      console.log("翻译后", prompt)
    } catch (err) {
      console.warn("翻译错误", err)
    }
  }

  const params = createRenderParams(prompt, config.useRecommendPrompt, {
    sessionId: String(userId),
    ...config,
  })

  const msg: ImageMessage = {
    ...params,
    originPrompt,
    translatedPrompt: needTranslate ? prompt : undefined,
    state: "initial",
  }

  const msgs = useSession.getState().imageSession
  useSession.setState({ imageSession: [...msgs, msg] })
  try {
    const resp = await api.render(params)
    if (resp.status === 200) {
      msg.renderTask = resp.data
      if (resp.data.queue >= 1) {
        // 当前队列正在排队
        msg.state = "pending"
      } else {
        msg.state = "rendering"
      }
      // 消耗一次使用情况
      reduceCoins(5 * params.numOutputs)
    } else {
      msg.state = "error"
      const errMsg = (resp.data as any)?.msg ?? "服务器繁忙，请稍后再试"
      addToast(errMsg, "error")
    }
    useSession.setState({ imageSession: [...msgs, msg] })
  } catch (err) {
    addToast("服务器繁忙，请稍后再试", "error")
  }
  return msg
}

export async function getImageStream(
  taskId: string | number,
  onProgress: (msg: ImageStep) => void,
  onFinish: (msg: ImageResult) => void,
  onError: (err: AppError) => void,
) {
  try {
    const resp = await api.image(taskId)
    const error = await filterError(resp)
    if (error) {
      onError(error)
      return
    }
    await handleSSE(resp, (message) => {
      console.log("on SSE", message)

      const data = JSON.parse(message)
      if (data.error || data.status === "failed") {
        console.log("message:", data);
        onError({
          msg: data?.detail ?? "绘制内部错误",
          code: 100
        })
      }
      if (data.total_steps) {
        onProgress(data as ImageStep)
      } else {
        onFinish(data as ImageResult)
      }
    })
  } catch (error: any) {
    onError({
      msg: error?.message ?? "图片请求错误",
      code: error?.cause
    })
  }
}


async function filterError(response: Response) {
  let error: AppError | null = null

  if (response.status === 400) {
    const res = await response.text()
    console.log("请求出错", res)
    const body = JSON.parse(res)
    error = {
      msg: body?.msg ?? `业务异常（${res}）`, code: 999
    }
  } else if (response.status === 401) {
    error = {
      msg: "登录过期", code: 401
    }
  } else if (response.status === 425) {
    // 稍后重试
    error = {
      msg: "服务器繁忙，稍后重试", code: 425
    }
  } else if (response.status > 400) {
    console.warn("请求出错", response)
    error = {
      msg: `服务器繁忙，稍后重试: ${response.status} ${response.statusText}`, code: response.status
    }
  } else if (!response.body) {
    error = {
      msg: `无响应,请检查网络: ${response.status}`, code: response.status
    }
  }
  return error
}

async function handleSSE(response: Response, onMessage: (message: string) => void) {
  if (!response.body) {
    console.warn("response body is null");
    return
  }
  if (response.headers.get("content-type")?.includes("application-json")) {
    const resp = await response.text()
    onMessage(resp)
    // xx 扣除点数
    return
  }
  const parser = createParser((event) => {
    if (event.type === "event") {
      onMessage(event.data)
    }
  })
  for await (const chunk of iterableStreamAsync(response.body)) {
    const str = new TextDecoder().decode(chunk)
    parser.feed(str)
  }
}

export async function* iterableStreamAsync(
  stream: ReadableStream,
): AsyncIterableIterator<Uint8Array> {
  const reader = stream.getReader()
  try {
    while (true) {
      const { value, done } = await reader.read()
      if (done) {
        return
      } else {
        yield value
      }
    }
  } finally {
    reader.releaseLock()
  }
}