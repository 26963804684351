import { v4 as uuidv4 } from 'uuid';

import { ImageStep, RenderTaskInfo } from "./server";
import { GraphStyle } from "./strapi";

export interface RenderParams {
    requestId: string
    sessionId?: string
    seed?: number
    model?: string
    modelName: string
    prompt: string
    negativePrompt?: string
    numOutputs: number
    numInferenceSteps: number
    width: number
    height: number
    initImage?: string
    promptStrength?: number
    samplerName: string
    showBase64Img: boolean
    activeTags: Omit<GraphStyle, "cover">[]
    useUpscale?: string
    upscaleAmount?: string
    loraName?: string
    loraAlpha?: number
    loraTrigger?: string
    useLoraModel?: string
    clipSkip?: boolean,
    guidanceScale: number
    showOnlyFilteredImage: boolean
}

export type ImageMessage = {
    originPrompt: string
    translatedPrompt?: string
    state: "initial" | "pending" | "rendering" | "finish" | "error"
    images?: string[]
    step?: ImageStep,
    renderTask?: RenderTaskInfo
} & RenderParams

// 起手式
export const recommendPrompt = "extremely detailed 8k wallpaper,(masterpiece),(best quality),"
// 负面词
//  worst quality, ugly, deformed,text, bad anatomy,bad proportions,extra arms, extra legs, fused fingers,extra digits, mutated hands, bad hands
const negativePrompt = "NSFW, naked, pussy, nude, nipple"

function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

/** 创建一个默认的渲染请求对象 */
export function createRenderParams(promptStr: string, addRecommendPrompt: boolean = true, params: Partial<RenderParams> = {}): RenderParams {
    const seed = params.seed || getRandomInt(9999999999)
    let prompt = addRecommendPrompt ? recommendPrompt + promptStr : promptStr
    const styleStr = params?.activeTags?.map((i) => `${i.key}`).join(",")
    if (styleStr) {
        prompt = styleStr + ", " + prompt 
    }
    if (params?.loraTrigger) {
        prompt = params.loraTrigger + ", " + prompt 
    }

    return {
        seed,
        prompt,
        negativePrompt,
        requestId: uuidv4(),
        numOutputs: 1,
        numInferenceSteps: 24,
        showOnlyFilteredImage: true,
        samplerName: "euler_a",
        guidanceScale: 7,
        showBase64Img: false,
        upscaleAmount: "0",
        width: 512,
        height: 512,
        // promptStrength: 0.7,
        modelName: "随机",
        // loraAlpha: 0.56,
        // useLoraModel: "polaroid", //reelmech1v2
        activeTags: [
        ],
        clipSkip: true,
        ...params
    }
}