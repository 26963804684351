import { create } from "zustand"
import { persist } from "zustand/middleware"

import { API } from "../types"

interface UserState {
  user: API.UserDetail | null,
  token: API.TokenBundle | null,
  vip: API.VipInfo | null,
  usedTimes: number

  setVip: (resp: API.VipResp) => void
  decreaseTimes: (coins?: number) => void
}

export const useUserInfo = create<UserState>()(
  persist(
    (set, get) => ({
      user: null,
      token: null,
      vip: null,
      usedTimes: 0,
      setVip: (resp: API.VipResp) => {
        set({ usedTimes: resp.gptTimes, vip: resp })
      },
      decreaseTimes: (coins?: number) => {
        const lastTimes = get().usedTimes - (coins ?? 1)
        set({ usedTimes: lastTimes })
      },
    }),
    {
      name: "user",
    },
  ),
)

export async function changeUserAttributes(key: keyof API.UserDetail, value: any) {
  const user = useUserInfo.getState().user
  if (user) {
    useUserInfo.setState({ user: { ...user, [key]: value } })
  }
}

export default useUserInfo
