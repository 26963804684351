import { Box, Button, IconButton, Stack } from "@mui/material"
import { t } from "i18next"
import { QRCodeSVG } from "qrcode.react"
import { BiLink, BiLinkExternal } from "react-icons/bi"

import useDialogState from "../state/dialog"
import useUserInfo from "../state/user"
import { base10To62 } from "../util/base62"
import BaseDialog from "./BaseDialog"
import { addToast } from "@/state"

const InviteDialog = () => {
  const inviteVisible = useDialogState((state) => state.inviteVisible)
  const user = useUserInfo((state) => state.user)
  if (!user) {
    return null
  }
  const code = base10To62(user.id)
  const host = new URL(window.location.href).host
  const url = host + "/landing?invite=" + code
  const saveImage = async () => {
    try {
      debugger
      const convert = await import("html-to-image")
      const card = document.getElementById("invite-card")
      if (card) {
        const blob = await convert.toBlob(card)
        if (blob) {
          const saver = await import("file-saver")
          saver.default.saveAs(blob, "邀请码.png")
        } else {
          console.error("blob error")
        }
      }
    } catch (e) {
      console.error("保存出错", e)
      addToast("保存出错", "error")
    }
  }

  const copyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url).then(() => {
        addToast(t("copied to clipboard"), "success")
      })
    } else {
      addToast("复制失败, 请检查网站设置", "warning")
    }
  }

  return (
    <BaseDialog
      open={inviteVisible}
      title="邀请好友得积分"
      onClose={(_) => useDialogState.setState({ inviteVisible: false })}
    >
      <Box
        id="invite-card"
        sx={{
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pb: 2,
          minWidth: 260,
          background: "linear-gradient(169deg, #539BFF 0%, #2962FF 100%)",
          color: "white",
        }}
      >
        <Box
          sx={{
            bgcolor: "white",
            color: "primary.main",
            px: 2,
            py: 0.5,
            fontSize: "small",
            borderRadius: "0 0 1rem 1rem",
            fontWeight: "bold",
          }}
        >
          专属邀请码
        </Box>
        <Box fontSize="large" fontWeight="bold" pt={1}>
          {code}
        </Box>
        <Box
          my={1}
          bgcolor="white"
          sx={{ width: 130, height: 130, borderRadius: 2, padding: "10px" }}
        >
          <QRCodeSVG value={url} width={110} height={110} />
        </Box>
        <Box fontSize="small">扫码注册，立即体验</Box>
        <Box mt={2}>
          <Box>
            快来加入
            <Box fontWeight="bold" component="span">
              文生万物
            </Box>
           ，畅玩AI
          </Box>
        </Box>
      </Box>
      <Stack direction="row" spacing={1} mt={2}>
        <Button fullWidth variant="outlined" onClick={saveImage}>
          保存图片
        </Button>
        <Button fullWidth variant="contained" onClick={copyLink}>
          复制链接
        </Button>
        <IconButton
          onClick={() => {
            window.open(url, "_blank")
          }}
          sx={{ border: "1px solid", borderColor: "primary.main", color: "primary.main" }}
        >
          <BiLinkExternal />
        </IconButton>
      </Stack>
    </BaseDialog>
  )
}

export default InviteDialog
