import { useUserInfo, useDialogState, addToast } from "@/state";
import { instance, refreshToken, getCurrentUser, userVipInfo } from './api'

instance.interceptors.request.use(
    function (config) {
        const token = useUserInfo.getState().token
        if (token) {
            if (token.expireTime > Date.now()) {
                config.headers.Authorization = "Bearer " + token.accessToken
            }
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    },
)

instance.interceptors.response.use(
    async (response) => {
        if (response.status === 400) {
            response.statusText = response.data.msg
        } else if (response.status === 401) {
            /// 先尝试refreshToken
            const result = await tryRefreshToken()
            if (!result && !useDialogState.getState().loginVisible) {
                useUserInfo.setState({ user: null, vip: null, token: null })
                addToast("请先登录", 'warning')
                useDialogState.setState({ loginVisible: true })
            }
        } else if (
            response.data.hasOwnProperty("data") &&
            !response.data.hasOwnProperty("totalElements")
        ) {
            response.data = response.data.data
            return response
        }
        return response
    },
    (error) => {
        return Promise.reject(error)
    },
)

export async function tryRefreshToken() {
    const token = useUserInfo.getState().token
    if (!token || token.expireTime < Date.now()) return false
    const resp = await refreshToken(token.refreshToken)
    if (resp.status > 399) return false
    let userState: Record<string, any> = {
        token: resp.data,
    }
    const user = (await getCurrentUser()).data
    if (user) {
        userState.user = user
    }
    const vipResp = await userVipInfo(user.id)
    if (vipResp.data) {
        userState.vip = vipResp.data
    }
    useUserInfo.setState(userState)
    return true
}
