import { useCallback, useEffect, useMemo, useState } from "react"

import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  IconButton,
  LinearProgress,
  MenuItem,
  Stack,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import {
  BiDotsVerticalRounded,
  BiDownload,
  BiEditAlt,
  BiFullscreen,
  BiRefresh,
  BiRightTopArrowCircle,
  BiShare,
  BiTrash,
} from "react-icons/bi"

import HDIcon from "@/images/HDIcon"
import { Logo } from "@/images/LogoText"
import { api, client } from "@/service"
import { addToast, useSession } from "@/state"
import { ImageMessage } from "@/types/image"
import { ImageStep } from "@/types/server"
import { isWechat } from "@/util/env"
import downloadImage from "@/util/file"

import StyledMenu from "../StyledMenu"
import { useAdvanceConfigState } from "./state"

const audio = new Audio("audio_success.mp3")

const useRefreshImage = (msg: ImageMessage) => {
  const { renderTask } = msg
  const taskId = renderTask?.task
  const state = msg.state

  const updateMsg = useCallback(
    (options: Partial<ImageMessage>) => {
      const updatedMsg: ImageMessage = {
        ...msg,
        ...options,
      }
      const msgs = useSession
        .getState()
        .imageSession.map((item) => (item.requestId === msg.requestId ? updatedMsg : item))
      useSession.setState({ imageSession: msgs })
    },
    [msg],
  )
  const loadImage = (taskId: number) => {
    client.getImageStream(
      taskId,
      (step) => {
        console.log(step)
        const state = step.step <= 0 ? "pending" : "rendering"
        updateMsg({
          state,
          step,
        })
      },
      (result) => {
        if (!result.output) {
          /// 服务器丢弃了绘画
          console.warn("result empty", result)
          return
        }
        updateMsg({
          images: result.output.map((item) => item.data),
          state: "finish",
        })
        useAdvanceConfigState.setState({ currentTask: undefined })
        audio.play()
      },
      (err) => {
        console.warn("image load error", err)
        useAdvanceConfigState.setState({ currentTask: undefined })
        if (err.code === 425) {
          return
        }
        console.log(err.msg)
        addToast(err.msg, "error")
        updateMsg({
          state: "error",
        })
      },
    )
  }
  useEffect(() => {
    if (taskId) {
      // loadImage(taskId)
      const interval = setInterval(() => {
        if (state !== "finish" && state !== "error") {
          loadImage(taskId)
        } else {
          clearInterval(interval)
        }
      }, 2000)
      return () => clearInterval(interval)
    }
  }, [state])
}

const FlatButton = styled(IconButton)({
  backgroundColor: "#7772",
  borderRadius: 8,
  backdropFilter: "blur(10px)",
  "&:hover": {
    backgroundColor: "#7779",
  },
})

const RenderButton = styled((props: ButtonProps) => (
  <Button variant="outlined" color="primary" size="small" {...props} />
))({
  borderRadius: 8,
  borderWidth: 1.5,
  padding: [4, 6],
  minWidth: 40,
  "&:hover": {
    borderWidth: 1.5,
  },
  "& .MuiButton-startIcon": {
    marginRight: 4,
  },
})

const TagBox = styled(Box)({
  fontSize: "x-small",
  borderRadius: 4,
  display: "inline-block",
  padding: ".2em .5em .3em",
  margin: ".25em .1em",
  fontWeight: "bold",
})

const GraphicBlock: React.FC<{ msg: ImageMessage }> = ({ msg }) => {
  const {
    requestId,
    modelName,
    loraName,
    originPrompt,
    images,
    state,
    step,
    renderTask,
    width,
    height,
    activeTags,
    useUpscale,
  } = msg
  useRefreshImage(msg)
  const [subMenuVisible, setSubMenuVisible] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const imageUrls = images?.map((image) => api.HOST + "/image/media/" + image)
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))

  const displayWidth = useMemo(
    () => (isMobile ? window.innerWidth - (16 + 16) * 2 : 280),
    [isMobile],
  )
  const displayHeight = (displayWidth * height) / width

  const handleShowMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setSubMenuVisible(true)
  }
  const handleClose = () => {
    setSubMenuVisible(false)
  }
  const onEdit = useCallback(() => {
    useAdvanceConfigState.setState({
      prompt: msg.originPrompt,
      styles: activeTags,
    })
  }, [])
  const onDelete = useCallback(() => {
    const imgs = useSession.getState().imageSession
    const index = imgs.findIndex((i) => i.requestId === requestId)
    if (index >= 0) {
      imgs.splice(index, 1)
      useSession.setState({ imageSession: [...imgs] })
      handleClose()
    }
  }, [requestId])

  const onUpscale = () => {
    const requestId = String(Math.floor(Math.random() * 999999999))
    // 放大参数
    client
      .renderImage(msg.originPrompt, {
        ...msg,
        requestId,
        useRecommendPrompt: true,
        useUpscale: "RealESRGAN_x4plus",
        upscaleAmount: "2",
        numInferenceSteps: 28,
      })
      .then((msg) => {
        useAdvanceConfigState.setState({ currentTask: msg.requestId })
      })
  }

  return (
    <Box id={requestId} sx={{ display: "flex", p: 2 }}>
      {!isMobile && (
        <Avatar>
          <Logo />
        </Avatar>
      )}
      <Box
        sx={{
          bgcolor: isMobile ? "background.paper" : "background.default",
          ml: isMobile ? 0 : 2,
          p: 2,
          borderRadius: 2,
        }}
      >
        <Box maxWidth={displayWidth} className="line-3">
          {originPrompt}
        </Box>
        <Box
          maxWidth={displayWidth}
          sx={{
            pt: 1,
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {modelName && <TagBox sx={{ color: "white", background: "#BB8BC8" }}>{modelName}</TagBox>}
          {loraName && <TagBox sx={{ color: "white", bgcolor: "#8C8ED7" }}>{loraName}</TagBox>}
        </Box>
        <Box
          maxWidth={displayWidth}
          sx={{
            pb: 1,
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {activeTags?.map((item) => (
            <TagBox
              color="text.secondary"
              sx={{ bgcolor: "background.main", border: "1px solid", borderColor: "divider" }}
            >
              #{item.name}
            </TagBox>
          ))}
        </Box>

        {state === "pending" && (
          <Stack
            direction="column"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            minWidth={displayWidth}
            height={200}
          >
            <CircularProgress />
            <Typography variant="h4">排队中</Typography>
            <Box color="text.secondary">前方剩余{renderTask?.queue ?? 0}人</Box>
          </Stack>
        )}
        {state === "rendering" && <FluentProgress displayWidth={displayWidth} step={step} />}
        {state === "error" && (
          <Alert
            severity="error"
            sx={{ mt: 1 }}
            action={
              <IconButton color="inherit" size="small" onClick={onDelete}>
                <BiTrash />
              </IconButton>
            }
          >
            绘制失败
          </Alert>
        )}
        {state === "finish" && (
          <>
            <Stack direction="row" flexWrap="wrap" maxWidth={2 * displayWidth + 10}>
              {imageUrls?.map((imageUrl) => (
                <ImageDetail
                  imageUrl={imageUrl}
                  displayWidth={displayWidth}
                  displayHeight={displayHeight}
                  hightDefinition={Boolean(useUpscale)}
                />
              ))}
            </Stack>
            <Stack direction="row" spacing={1} mt={1}>
              <RenderButton startIcon={<BiEditAlt />} onClick={onEdit}>
                修改
              </RenderButton>
              <RenderButton
                startIcon={<BiRefresh />}
                onClick={() => {
                  const seed = Math.floor(Math.random() * 999999999)
                  const requestId = String(seed)
                  client.renderImage(msg.originPrompt, {
                    ...msg,
                    seed,
                    requestId,
                    useRecommendPrompt: true,
                  })
                  useAdvanceConfigState.setState({ currentTask: requestId })
                }}
              >
                重画
              </RenderButton>
              <RenderButton startIcon={<BiRightTopArrowCircle />} onClick={onUpscale}>
                高清
              </RenderButton>
              <IconButton sx={{ bgcolor: "primary.main", display: "none" }}>
                <BiShare />
              </IconButton>
              <Box flexGrow={1} />
              <RenderButton variant="outlined" onClick={handleShowMenu}>
                <BiDotsVerticalRounded fontSize="large" />
              </RenderButton>
            </Stack>
          </>
        )}
        <SubMenu
          open={subMenuVisible}
          onDelete={onDelete}
          handleClose={handleClose}
          anchorEl={anchorEl}
        />
      </Box>
    </Box>
  )
}

type FluentProgressProps = {
  displayWidth: number
  step?: ImageStep
}

const FluentProgress: React.FC<FluentProgressProps> = ({ displayWidth, step }) => {
  const remainTime = step && (step.step_time * (step.total_steps - step.step)).toFixed(1)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (step) {
      const actProgress = (100 * (step.step + 1)) / step.total_steps
      if (actProgress > progress) {
        setProgress(actProgress)
      }
    }
  }, [step])
  useEffect(() => {
    const out = setTimeout(() => {
      if (progress < 92) {
        setProgress(progress + 8)
      }
    }, 300)
    return () => clearTimeout(out)
  }, [progress])

  return (
    <Stack
      direction="column"
      spacing={1}
      alignItems="center"
      justifyContent="center"
      minWidth={displayWidth}
      height={200}
    >
      <Typography variant="h4" mb={1}>
        绘制中
      </Typography>
      {step && <Box>{progress?.toFixed(2)} %</Box>}
      <LinearProgress
        variant={step ? "determinate" : "indeterminate"}
        value={progress}
        sx={{ width: "100%" }}
      />
      {remainTime && <Box color="text.secondary">预计剩余时间 {remainTime}秒</Box>}
    </Stack>
  )
}

type ImageDetailProps = {
  imageUrl: string
  displayWidth: number
  displayHeight: number
  hightDefinition: boolean
}

const ImageDetail: React.FC<ImageDetailProps> = ({
  imageUrl,
  displayWidth,
  displayHeight,
  hightDefinition,
}) => {
  const inWeixin = useMemo(() => isWechat(), [])
  const [proviewVisible, setProviewVisible] = useState(false)
  const showProview = () => {
    setProviewVisible(true)
  }

  return (
    <Box sx={{ position: "relative", width: displayWidth, height: displayHeight }} m={0.2}>
      <img
        src={imageUrl}
        style={{
          width: displayWidth,
          height: displayHeight,
          borderRadius: 8,
        }}
      />
      {hightDefinition && (
        <HDIcon
          sx={{ position: "absolute", top: 12, left: 12, color: "#444", width: 30, height: 30 }}
        />
      )}
      <Stack spacing={1} sx={{ position: "absolute", top: 12, right: 12 }}>
        <FlatButton onClick={showProview}>
          <BiFullscreen />
        </FlatButton>
        {!inWeixin && (
          <FlatButton onClick={() => downloadImage(imageUrl)}>
            <BiDownload />
          </FlatButton>
        )}
      </Stack>
      <Backdrop
        open={proviewVisible}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        onClick={() => setProviewVisible(false)}
      >
        <img src={imageUrl} style={{ maxWidth: "90%", maxHeight: "90%", zIndex: 99 }} />
      </Backdrop>
    </Box>
  )
}

type SubMenuProps = {
  open: boolean
  onDelete: () => void
  anchorEl: HTMLElement | null
  handleClose: () => void
}

const SubMenu: React.FC<SubMenuProps> = ({ open, onDelete, handleClose, anchorEl }) => {
  const { t } = useTranslation()

  return (
    <StyledMenu open={open} onClose={handleClose} anchorEl={anchorEl}>
      <MenuItem onClick={onDelete} disableRipple sx={{ color: "red" }}>
        <Box sx={{ height: 28, width: 28, display: "flex", alignItems: "center" }}>
          <BiTrash fontSize="large" />
        </Box>
        {t("delete")}
      </MenuItem>
    </StyledMenu>
  )
}

export default GraphicBlock
