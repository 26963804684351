import React from "react"

import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material"
import { AiFillWechat } from "react-icons/ai"
import {
  BiChat,
  BiCoinStack,
  BiDesktop,
  BiGift,
  BiLinkExternal,
  BiLogOut,
  BiMoon,
  BiShoppingBag,
  BiSlider,
  BiSun,
  BiUserCircle,
} from "react-icons/bi"
import { useNavigate } from "react-router-dom"
import { shallow } from "zustand/shallow"

import useDialogState from "../state/dialog"
import useUserInfo from "../state/user"
import { ThemeMode } from "../theme"
import { useThemeSwicher } from "../theme/ThemeSwitcher"
import { useSettingsState } from "./SettingWindow"
import FullLogo from "@/images/LogoText"

const ProfileMenu: React.FC = () => {
  const [theme, { setMode }] = useThemeSwicher()
  const navigate = useNavigate()

  const [user, vipInfo, usedTimes] = useUserInfo(
    (state) => [state.user, state.vip, state.usedTimes],
    shallow,
  )

  const toggleTheme = () => {
    const newTheme = theme === ThemeMode.Light ? ThemeMode.Dark : ThemeMode.Light
    setMode(newTheme)
    useSettingsState.setState({ theme: newTheme })
  }
  const logout = async () => {
    useUserInfo.setState({ user: null, vip: null, token: null })
    useDialogState.setState({ profileVisible: false, loginVisible: true })
  }

  if (!user) {
    return (
      <Stack p={3} alignItems="center" spacing={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            p: 1,
            mt: 1,
            mb: 4,
          }}
        >
          <FullLogo sx={{ width: 175, height: 57, fill: (theme) => theme.palette.text.primary }} />
        </Box>
        请在登录后使用
        <Button
          fullWidth
          variant="contained"
          onClick={() => useDialogState.setState({ loginVisible: true })}
        >
          立即登录
        </Button>
      </Stack>
    )
  }
  return (
    <>
      <Box
        sx={{
          p: 2,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <IconButton
          onClick={toggleTheme}
          sx={{
            position: "absolute",
            top: 1,
            right: 1,
          }}
        >
          {theme === ThemeMode.Light ? <BiSun /> : <BiMoon />}
        </IconButton>
        <Avatar alt="user avatar" src={user?.avatar} sx={{ width: 56, height: 56 }} />
        <Box fontSize="large">{user?.nickname}</Box>
        <Box fontSize="x-small" color="gray" mt={0.5}>
          每天赠送5积分，当天有效。
        </Box>
        {vipInfo?.member === 0 && (
          <Button onClick={() => useDialogState.setState({ orderVisible: true })}>
            获得更多积分
          </Button>
        )}
      </Box>
      <Divider sx={{ mb: 1 }} />
      <MenuItem onClick={() => useDialogState.setState({ socialMediaVisible: true })}>
        <ListItemIcon>
          <AiFillWechat />
        </ListItemIcon>
        AI交流群
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/wallet")
          useDialogState.setState({ profileVisible: false })
        }}
      >
        <ListItemIcon>
          <BiCoinStack />
        </ListItemIcon>
        <ListItemText>我的积分</ListItemText>
        <Box color="goldenrod">{usedTimes}</Box>
      </MenuItem>
      <MenuItem
        onClick={() => {
          useDialogState.setState({ orderVisible: true })
        }}
      >
        <ListItemIcon>
          <BiShoppingBag />
        </ListItemIcon>
        <ListItemText>购买积分</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => useDialogState.setState({ inviteVisible: true })}>
        <ListItemIcon>
          <BiGift />
        </ListItemIcon>
        <ListItemText
          sx={{
            "& .MuiListItemText-secondary": {
              color: "primary.main",
              fontSize: "x-small",
            },
          }}
          primary="推荐有礼"
          secondary="推荐好友得50积分"
        />
      </MenuItem>

      <Divider sx={{ my: 0.5 }} />
      <MenuItem onClick={() => useDialogState.setState({ settingVisible: true })}>
        <ListItemIcon>
          <BiUserCircle />
        </ListItemIcon>
        资料设置
      </MenuItem>
      <MenuItem onClick={() => useDialogState.setState({ settingVisible: true })}>
        <ListItemIcon>
          <BiChat />
        </ListItemIcon>
        聊天设置
      </MenuItem>
      <MenuItem onClick={() => useDialogState.setState({ settingVisible: true })}>
        <ListItemIcon>
          <BiSlider />
        </ListItemIcon>
        系统设置
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem onClick={() => window.open("https://chat.qihuaitech.com", "_blank")}>
        <ListItemIcon>
          <BiDesktop />
        </ListItemIcon>
        <ListItemText primary="官网" />
        <BiLinkExternal />
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem onClick={logout}>
        <ListItemIcon>
          <BiLogOut />
        </ListItemIcon>
        退出登录
      </MenuItem>
    </>
  )
}

export default ProfileMenu
