import React, { useCallback, useMemo, useRef, useState } from "react"

import { Player } from "@lottiefiles/react-lottie-player"
import { BorderAllRounded } from "@mui/icons-material"
import CropLandscapeIcon from "@mui/icons-material/CropLandscape"
import CropPortraitIcon from "@mui/icons-material/CropPortrait"
import CropSquareIcon from "@mui/icons-material/CropSquare"
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Stack,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material"
import { grey } from "@mui/material/colors"
import { useRequest } from "ahooks"
import { BiLabel, BiTrash, BiUpload, BiX } from "react-icons/bi"
import { shallow } from "zustand/shallow"

import YouhuaIcon from "@/images/YouhuaIcon"
import { client, strapi } from "@/service"
import { addToast } from "@/state"
import { GraphStyle, ImageModel, LoraModel } from "@/types/strapi"

import GradientButton from "../Button"
import { CheckBoxOutlined as CheckBox } from "../CheckBox"
import { AntTab, AntTabs } from "../Tabs"
import { defaultRation, handleImageFile, useAdvanceConfigState } from "./state"

type AdvanceConfigProps = {}

// AI 生成提示词的状态
type GenerationState = {
  loading: boolean
  visible: boolean
  prompt?: string
  translation?: string
}

/**
 * 高级出图设置
 */
const AdvanceConfig: React.FC<AdvanceConfigProps> = () => {
  const [suspend, setSuspend] = useState<boolean>(false)
  const [tabIndex, setTabIndex] = useState("common")
  const [prompt, initImage, rationType, ration, options] = useAdvanceConfigState(
    (state) => [state.prompt, state.initImage, state.rationType, state.ration, state.options],
    shallow,
  )
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))
  const maxWidth = isMobile ? "100%" : 350
  const inputElement = useRef<any>()
  const aiElement = useRef<HTMLButtonElement>(null)
  const [promptGeneration, setPromptGeneration] = useState<GenerationState>({
    loading: false,
    visible: false,
  })

  const renderImage = useCallback(async () => {
    setSuspend(true)
    const { prompt, ration, initImage, model, styles, lora, numOutputs } =
      useAdvanceConfigState.getState()

    const scale = localStorage.getItem("scale") !== null ? 2 : 1
    const msg = await client.renderImage(prompt, {
      model: model.key,
      initImage,
      useRecommendPrompt: false,
      activeTags: styles,
      width: ration.width * scale,
      height: ration.height * scale,
      modelName: model.title,
      useLoraModel: lora?.file,
      loraAlpha: lora?.alpha,
      loraName: lora?.title,
      loraTrigger: lora?.trigger,
      numOutputs,
    })

    useAdvanceConfigState.setState({ currentTask: msg.requestId, configVisible: false })
    setSuspend(false)
  }, [])

  const onFileChange = useCallback(async (event?: React.ChangeEvent<HTMLInputElement>) => {
    try {
      handleImageFile(event?.target?.files)
    } catch (error: unknown) {
      if (error instanceof Error) return addToast(error.message, "warning")
    }
  }, [])
  const chooseImage = () => {
    inputElement.current?.click()
  }
  const generatePrompt = async () => {
    if (!prompt) {
      addToast("请先输入一个简短的描述", "warning")
      return
    }

    setPromptGeneration({ ...promptGeneration, visible: true, loading: true })
    // 等API完成
    const result = await client.genrateImagePropmt(prompt)
    console.log(result)
    try {
      if (result) {
        const obj = JSON.parse(result)
        console.log(result, obj)
        setPromptGeneration({ ...promptGeneration, ...obj, visible: true, loading: false })
      }
    } catch (e) {
      console.warn(e)
      addToast("出现错误，请注意描述遵守道德准则", "warning")
      setPromptGeneration({ ...promptGeneration, visible: false, loading: false })
    }
  }

  return (
    <Box
      sx={{
        minWidth: 260,
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Toolbar>
        <Typography variant="h6" noWrap sx={{ flexGrow: 1, flexShrink: 1 }}>
          高级绘图设置
        </Typography>
        {isMobile && (
          <IconButton onClick={() => useAdvanceConfigState.setState({ configVisible: false })}>
            <BiX />
          </IconButton>
        )}
      </Toolbar>
      <Divider />
      <Box sx={{ flex: 1, overflowY: "auto", p: 1.5, pb: 7 }}>
        <SectionTitle title="画面描述" subTitle="(最大长度500)" />
        <Box sx={{ position: "relative" }}>
          <OutlinedInput
            multiline
            fullWidth
            rows={6}
            value={prompt}
            sx={{ bgcolor: "background.default" }}
            inputProps={{ maxLength: 500 }}
            placeholder="使用短语或者单词描述效果更好,控制在100个词以内,中英文都可以。"
            onChange={(e) => useAdvanceConfigState.setState({ prompt: e.target.value })}
          />
          <Button
            ref={aiElement}
            onClick={generatePrompt}
            sx={{
              position: "absolute",
              bottom: 3,
              right: 3,
              "&:hover": {
                opacity: 0.8,
              },
            }}
          >
            <YouhuaIcon
              sx={{ width: 64, height: 22, fill: (theme) => theme.palette.text.primary }}
            />
          </Button>
        </Box>
        <SectionTitle title="参考图" subTitle="图片不会被服务器保存" />
        <input
          type="file"
          id="image-file-selector"
          hidden
          accept="image/*"
          ref={inputElement}
          onChange={onFileChange}
        />
        <Button variant="outlined" onClick={chooseImage}>
          <BiUpload /> 上传图片
        </Button>
        {initImage && (
          <Box sx={{ mt: 1, maxWidth: 330, height: 120, position: "relative" }}>
            <IconButton
              size="small"
              sx={{
                position: "absolute",
                top: 6,
                left: 6,
                bgcolor: "#fff8",
              }}
              onClick={() =>
                useAdvanceConfigState.setState({ initImage: undefined, ration: defaultRation })
              }
            >
              <BiX />
            </IconButton>
            <img className="advanceImage" src={initImage} />
          </Box>
        )}
        <SectionTitle title="模型" />
        <ModelList />
        <SectionTitle title="绘画风格" subTitle="可选" />
        <Gallery style="style" maxWidth={maxWidth} />
        <SectionTitle title="热门描述" subTitle="可多选" />
        <SelectedStyles maxWidth={maxWidth} />
        <AntTabs
          value={tabIndex}
          variant="scrollable"
          sx={{ maxWidth }}
          onChange={(_, v) => setTabIndex(v)}
        >
          <AntTab value="common" label="常用" />
          <AntTab value="camera" label="构图" />
          <AntTab value="clothes" label="服饰" />
          <AntTab value="action" label="动作" />
          <AntTab value="scene" label="场景" />
          <AntTab value="artist" label="艺术类型" />
        </AntTabs>
        <ChipGrid maxWidth={maxWidth} style={tabIndex} />

        {!initImage && (
          <>
            <SectionTitle title="画面比例" />
            <Stack spacing={1} direction="row">
              <CheckBox
                selected={rationType === 0}
                onClick={(_) => {
                  useAdvanceConfigState.getState().changeType(0)
                }}
              >
                <CropSquareIcon /> 正方形
              </CheckBox>
              <CheckBox
                selected={rationType === 1}
                onClick={(_) => {
                  useAdvanceConfigState.getState().changeType(1)
                }}
              >
                <CropPortraitIcon /> 竖图
              </CheckBox>
              <CheckBox
                selected={rationType === 2}
                onClick={(_) => {
                  useAdvanceConfigState.getState().changeType(2)
                }}
              >
                <CropLandscapeIcon /> 横图
              </CheckBox>
            </Stack>
            <Select
              id="shape"
              size="small"
              value={ration.value}
              fullWidth
              sx={{ mt: 1 }}
              onChange={(e) => {
                if (typeof e.target.value === "number") {
                  const ration = options.find((i) => i.value === e.target.value)
                  if (ration) useAdvanceConfigState.setState({ ration })
                }
              }}
            >
              {options.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <SectionTitle title="出图数量" />
            <OutputNumber />
          </>
        )}
        <AiPopover
          anchor={aiElement.current}
          generation={promptGeneration}
          onClose={() => setPromptGeneration({ ...promptGeneration, visible: false })}
        />
      </Box>
      <GradientButton
        size="large"
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          m: 1,
          borderRadius: 8,
          width: "calc(100% - 20px)",
        }}
        disabled={suspend}
        onClick={renderImage}
      >
        开始绘图
      </GradientButton>
    </Box>
  )
}

const OutputNumber = () => {
  const [numOutputs] = useAdvanceConfigState((state) => [state.numOutputs], shallow)
  return (
    <Stack spacing={1} direction="row">
      {[1, 2, 3, 4].map((num) => (
        <CheckBox
          selected={numOutputs === num}
          onClick={(_) => {
            useAdvanceConfigState.setState({ numOutputs: num })
          }}
        >
          {num}张
        </CheckBox>
      ))}
    </Stack>
  )
}

type AiPopoverProps = {
  generation: GenerationState
  anchor: HTMLElement | null
  onClose: () => void
}

const SmallTitle = styled(Box)(({ theme }) => ({
  fontSize: "small",
  fontWeight: "bold",
  color: theme.palette.primary.main,
}))

const TextContent = styled(Box)({
  maxWidth: 300,
  backgroundColor: "#8883",
  padding: 6,
  borderRadius: 4,
  fontSize: "small",
  marginBottom: 12,
})

const AiPopover: React.FC<AiPopoverProps> = ({ generation, anchor, onClose }) => {
  const copyPrompt = () => {
    useAdvanceConfigState.setState({ prompt: generation.prompt })
    onClose()
  }
  return (
    <Popover
      open={generation.visible}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      elevation={3}
      onClose={onClose}
    >
      <Box p={1}>
        {generation.loading && (
          <Box width={300} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Player
              autoplay
              loop
              style={{ height: "100px", width: "100px" }}
              src="/ai_loading.json"
            />
            <Box fontSize="small" color="gray">
              正在生成提示词
            </Box>
          </Box>
        )}
        {!generation.loading && generation.prompt && generation.translation && (
          <Box>
            <Typography variant="subtitle2" mb={0.5}>
              AI生成结果
            </Typography>
            <SmallTitle>画面描述</SmallTitle>
            <TextContent whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {generation.prompt}
            </TextContent>
            <SmallTitle>翻译</SmallTitle>
            <TextContent>{generation.translation}</TextContent>
            <Box textAlign="end">
              <Button variant="contained" size="small" onClick={copyPrompt}>
                使用
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Popover>
  )
}

type SectionTitleProps = {
  title: string
  tip?: string
  subTitle?: string
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title, tip, subTitle }) => {
  return (
    <Box sx={{ display: "flex", fontSize: "small", mt: 1, py: 1 }}>
      <Box
        sx={{
          fontWeight: "bold",
          mr: 1,
          color: "primary.main",
          display: "flex",
          alignItems: "center",
        }}
      >
        <BiLabel style={{ marginRight: 5 }} />
        {title}
      </Box>
      <Box sx={{ color: grey[600] }}>{subTitle}</Box>
    </Box>
  )
}

type LimitBox = {
  maxWidth: number | string
}

type GalleryProps = {
  style: string
}

function useSplit<T>(list?: T[]) {
  const splitedList = useMemo(() => {
    if (!list || list.length === 0) {
      return []
    }
    if (list.length < 5) {
      return [list]
    } else if (list.length < 10) {
      const middleIndex = Math.floor(list.length / 2)
      return [list.slice(0, middleIndex), list.slice(middleIndex)]
    } else {
      const firstThirdIndex = Math.ceil(list.length / 3)
      const secondThirdIndex = Math.ceil((list.length * 2) / 3)
      return [
        list.slice(0, firstThirdIndex),
        list.slice(firstThirdIndex, secondThirdIndex),
        list.slice(secondThirdIndex),
      ]
    }
  }, [list])
  return splitedList
}

function useInclude(item: GraphStyle, list: { key: string }[]) {
  const index = list.findIndex((i) => i.key === item.key)
  return index > -1
}

const Gallery: React.FC<GalleryProps & LimitBox> = ({ style, maxWidth }) => {
  const { data: list } = useRequest(() => strapi.getLoraList(), {
    cacheKey: "lora-" + style,
    cacheTime: 5000,
  })
  const splitedList = useSplit(list)
  return (
    <Box sx={{ overflowX: "auto", maxWidth, pb: 1 }}>
      {splitedList.map((list, index) => (
        <Stack key={index} direction="row" spacing={1} mt={1}>
          {list.map((item) => (
            <ImageBox key={item.file} item={item} />
          ))}
        </Stack>
      ))}
    </Box>
  )
}

const ModelList: React.FC = () => {
  const { data, loading } = useRequest(strapi.getModelList, {
    cacheKey: "models",
    staleTime: 30000,
  })
  return (
    <Stack direction="row" spacing={1} mt={1}>
      {loading && <LinearProgress />}
      {data?.map((item) => (
        <ModelBox key={item.key} item={item} />
      ))}
    </Stack>
  )
}

// 模型选择
const ModelBox: React.FC<{ item: ImageModel }> = ({ item }) => {
  const { title, cover, key } = item
  const thumbnail = cover && cover?.data?.attributes.formats.thumbnail.url
  const currentModel = useAdvanceConfigState((state) => state.model)
  const className = "container " + (currentModel.key === key ? "active" : "")
  return (
    <Box className="modelItem" onClick={() => useAdvanceConfigState.setState({ model: item })}>
      <Box
        className={className}
        sx={{
          backgroundImage: `url(${thumbnail})`,
          bgcolor: currentModel.key === key ? "inhert" : "#3339",
        }}
      >
        {title}
      </Box>
    </Box>
  )
}

type ImageBoxProps = {
  item: GraphStyle
}

const ImageBox: React.FC<{ item: LoraModel }> = ({ item }) => {
  const { title, file, cover } = item
  const thumbnail = cover && cover?.data?.attributes.formats.thumbnail.url
  const lora = useAdvanceConfigState((state) => state.lora)
  const selected = lora?.file === item.file
  const onSelect = () => {
    useAdvanceConfigState.setState({ lora: selected ? undefined : item })
  }
  return (
    <Box
      onClick={onSelect}
      sx={{
        width: 80,
        height: 80,
        minWidth: 80,
        position: "relative",

        mr: 1,
      }}
    >
      {thumbnail && (
        <img
          src={thumbnail}
          alt={file}
          loading="lazy"
          style={{
            objectFit: "cover",
            width: "100%",
            height: "100%",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        />
      )}
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          bottom: 0,
          left: 0,
          p: 0.3,
          fontSize: "small",
          bgcolor: selected ? "#8C8ED7aa" : "#3338",
          textAlign: "center",
          color: "white",
          fontWeight: "bold",
          backdropFilter: "blur(10px)",
          borderRadius: "0 0 8px 8px",
        }}
      >
        {title}
      </Box>
    </Box>
  )
}

const SelectedStyles: React.FC<LimitBox> = ({ maxWidth }) => {
  const styles = useAdvanceConfigState((state) => state.styles)
  const selectedItems = styles.filter((item) => item.type !== "style")
  const onClear = () => {
    const result = styles.filter((item) => item.type === "style")
    useAdvanceConfigState.setState({ styles: result })
  }
  return (
    <Box
      maxWidth={maxWidth}
      sx={{
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {selectedItems.map((item) => (
        <Box
          key={item.key}
          sx={{
            fontSize: "small",
            paddingX: 1,
            paddingY: 0.3,
            mr: 0.3,
            mb: 0.3,
          }}
        >
          #{item.name}
        </Box>
      ))}

      {selectedItems.length > 1 && (
        <IconButton size="small" onClick={onClear}>
          <BiTrash />
        </IconButton>
      )}
    </Box>
  )
}

const ChipGrid: React.FC<GalleryProps & LimitBox> = ({ style, maxWidth }) => {
  const { data: list } = useRequest(() => strapi.getStyleList(style), {
    refreshDeps: [style],
    cacheKey: "paint-" + style,
    cacheTime: 10000,
  })
  const splitedList = useSplit(list)
  return (
    <Box maxWidth={maxWidth} overflow="auto" pb={1}>
      {splitedList.map((list, index) => {
        return (
          <Stack key={index} direction="row" spacing={1} mt={1}>
            {list.map((item) => (
              <ChipItem key={item.key} item={item} />
            ))}
          </Stack>
        )
      })}
    </Box>
  )
}

const ChipItem: React.FC<ImageBoxProps> = ({ item }) => {
  const styles = useAdvanceConfigState((state) => state.styles)
  const selected = useInclude(item, styles)
  const onSelect = () => {
    useAdvanceConfigState.getState().toggleStyles(item)
  }
  return (
    <Chip label={item.name} clickable onClick={onSelect} color={selected ? "primary" : "default"} />
  )
}

export default AdvanceConfig
