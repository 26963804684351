import React, { memo } from "react"

import {
  Avatar,
  Box,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuList,
  MenuItem as MuiMenuItem,
  Stack,
  styled,
} from "@mui/material"
import { BiChevronRightCircle, BiImage, BiMessageDetail, BiUserCircle } from "react-icons/bi"
import { shallow } from "zustand/shallow"

import CoinIcon from "@/images/CoinIcon"
import Logo from "@/images/LogoText"
import useDialogState from "@/state/dialog"
import useSession from "@/state/session"
import { useUserInfo } from "@/state/user"
import { API, Session } from "@/types"

import ChatConfigWindow from "../ChatConfigWindow"
import ProfileMenu from "../ProfileMenu"

const MenuItem = styled(MuiMenuItem)({
  borderRadius: 8,
})

export const PrimaryMenuItem = styled(MuiMenuItem)(({ theme }) => ({
  borderRadius: 6,
  marginTop: theme.spacing(0.5),
  minWidth: 140,
  color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
  "&.MuiSvgIcon-root": {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1.5),
  },
  "&.Mui-selected": {
    backgroundImage: "linear-gradient(90deg, #D1AAD7 0%, #C88BC4 26%, #7B8FDD 77%, #69B8FF 100%)",
    color: "white",
  },
}))

type AppMenuProps = {
  className: string
}

const AppMenu: React.FC<AppMenuProps> = ({ className }) => {
  const [mode, updateChatSession] = useSession(
    (state) => [state.mode, state.updateChatSession],
    shallow,
  )

  const [configureChatConfig, setConfigureChatConfig] = React.useState<Session | null>(null)

  const handleChangeMode = (mode: "chat" | "image") => {
    useSession.setState({ mode })
  }

  return (
    <Stack
      className={className}
      sx={{
        height: "100%",
        p: 2,
        bgcolor: "background.default",
        position: "relative",
      }}
      spacing={1}
    >
      <Box p={1}>
        <Logo sx={{ width: 175, height: 57, fill: (theme) => theme.palette.text.primary }} />
      </Box>

      <MenuList>
        <PrimaryMenuItem selected={mode === "chat"} onClick={() => handleChangeMode("chat")}>
          <Box m={1} fontSize="large" height={18}>
            <BiMessageDetail />
          </Box>
          <ListItemText>AI聊天</ListItemText>
        </PrimaryMenuItem>
        <PrimaryMenuItem selected={mode === "image"} onClick={() => handleChangeMode("image")}>
          <Box m={1} fontSize="large" height={18}>
            <BiImage />
          </Box>
          <ListItemText>Ai绘图</ListItemText>
        </PrimaryMenuItem>
      </MenuList>

      <Box flex={1} />
      <UserComponent key="user component" />

      {configureChatConfig !== null && (
        <ChatConfigWindow
          open={configureChatConfig !== null}
          session={configureChatConfig}
          save={(session) => {
            updateChatSession(session)
            setConfigureChatConfig(null)
          }}
          close={() => setConfigureChatConfig(null)}
        />
      )}
    </Stack>
  )
}

const BoxCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
}))

function getVipProps(vip?: API.VipInfo | null) {
  if ((vip?.member ?? 0) > 0) {
    return { title: "vip", color: "goldenrod" }
  }
  return { title: "体验版", color: "grey" }
}

const UserComponent = memo(() => {
  const [user, vipInfo, usedTimes] = useUserInfo(
    (state) => [state.user, state.vip, state.usedTimes],
    shallow,
  )
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isLogined = Boolean(user)
  const profileVisible = useDialogState((state) => state.profileVisible)
  const handleClose = () => {
    useDialogState.setState({ profileVisible: false })
  }
  const vipBadge = getVipProps(vipInfo)
  const usegePercent = (usedTimes * 100) / 5
  if (!isLogined || !user) {
    return (
      <BoxCard>
        <MenuItem
          sx={{ bgcolor: "background.default" }}
          onClick={() => useDialogState.setState({ loginVisible: true })}
        >
          <ListItemIcon sx={{ py: 1 }}>
            <BiUserCircle size="20" />
          </ListItemIcon>
          <ListItemText primary="立即登录" secondary="登录后免费使用"></ListItemText>
        </MenuItem>
      </BoxCard>
    )
  }

  return (
    <BoxCard>
      <Menu
        elevation={3}
        id="account-menu"
        anchorEl={anchorEl}
        open={profileVisible}
        onClose={handleClose}
        sx={{
          "& .MuiMenu-list": {
            p: 1,
            minWidth: "218px",
          },
        }}
      >
        <ProfileMenu />
      </Menu>
      <Box
        display="flex"
        alignItems="center"
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          useDialogState.setState({ profileVisible: true })
        }}
      >
        <Avatar src={user.avatar} />
        <Box pl={1} flexGrow={1} fontSize="small">
          <Box display="flex" alignItems="center">
            <Box fontWeight="bold" fontSize="medium" ml={0.5}>
              {user.nickname ?? "用户"}
            </Box>
            <Box
              display="inline-block"
              sx={{
                bgcolor: vipBadge.color,
                px: 0.4,
                borderRadius: 1,
                fontSize: 8,
                color: "white",
                ml: 1,
              }}
            >
              {vipBadge.title}
            </Box>
          </Box>
          {vipInfo && vipInfo.member > 0 && (
            <Box color="#888" mt={0.5}>
              <Box display="flex" alignItems="center">
                <CoinIcon fontSize="small" />
                <Box>{usedTimes}</Box>
              </Box>
            </Box>
          )}
        </Box>

        <BiChevronRightCircle fontSize={24} color="gray" />
      </Box>
      {vipInfo && vipInfo.member === 0 && (
        <>
          <Box
            sx={{
              fontSize: "small",
              color: "#777",
              mt: 2,
              mb: 0.5,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>今日剩余</Box>
            <Box>{usedTimes}/5</Box>
          </Box>
          <LinearProgress value={usegePercent} variant="determinate" />
        </>
      )}
    </BoxCard>
  )
})

export default AppMenu
