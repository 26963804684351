import { MenuItem, alpha, styled } from "@mui/material"

const CheckBox = styled(MenuItem)(({ theme }) => ({
  border: "1px solid",
  padding: [theme.spacing(2.5), theme.spacing(1.5)],
  borderRadius: 14,
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  position: "relative",
  borderColor: "#aaa",
  backgroundColor: theme.palette.background.default,

  "&.Mui-selected": {
    color: "white",
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
  },
  "&.Mui-selected:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.8),
  },
}))

export const CheckBoxOutlined = styled(CheckBox)(({ theme }) => ({
  justifyContent: "center",
  padding: theme.spacing(1),
  borderWidth: 2,
  "&.Mui-selected": {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: "transparent",
  },
  "&.Mui-selected:hover": {
    backgroundColor: alpha(theme.palette.primary.main, 0.3),
  },
}))

export default CheckBox
