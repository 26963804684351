import { PaletteMode } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';

export enum ThemeMode {
  Dark,
  Light,
  System,
}

export type RealThemeMode = Exclude<ThemeMode, ThemeMode.System>;

const ThemeModeMapPaletteMode: Record<RealThemeMode, PaletteMode> = {
  [ThemeMode.Dark]: 'dark',
  [ThemeMode.Light]: 'light',
};

export function fetchThemeDesign(mode: RealThemeMode, fontSize: number): ThemeOptions {
  return {
    palette: {
      primary: {
        dark: "#4B8FCC",
        main: "#69B8FF",
        light: "#A3D3FF",
      },
      mode: ThemeModeMapPaletteMode[mode],
      ...(mode === ThemeMode.Light
        ? {
          background: {
            default: "#f5f5f5",
          },
        }
        : {
          background: {
            default: "#000",
            paper: "rgb(40, 40, 40)",
          },
        }),
    },
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      fontSize,
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
          elevation1: {
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
          },
          elevation3: {
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "& .MuiDialogContent-before": {},
          },
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            borderRadius: "22px",
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: "12px",
            minWidth: '66px'
          },
        },
      }
    },
  };
}
