import React from "react"

import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import EditIcon from "@mui/icons-material/Edit"
import FileCopyIcon from "@mui/icons-material/FileCopy"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { BiCopy, BiDuplicate, BiEditAlt, BiMessageDots, BiTrashAlt } from "react-icons/bi"

import { Session } from "../types"
import StyledMenu from "./StyledMenu"

const { useState } = React

export interface Props {
  session: Session
  selected: boolean
  switchMe: () => void
  deleteMe: () => void
  copyMe: () => void
}

export default function SessionItem(props: Props) {
  const { t } = useTranslation()
  const { session, selected, switchMe, deleteMe, copyMe  } = props
  const [hovering, setHovering] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <MenuItem
        key={session.id}
        selected={selected}
        onClick={() => switchMe()}
        onMouseEnter={() => {
          setHovering(true)
        }}
        onMouseOver={() => {
          setHovering(true)
        }}
        onMouseLeave={() => {
          setHovering(false)
        }}
        sx={{ borderRadius: 2, mb: 0.5 }}
      >
        <ListItemIcon>
          <IconButton>
            <BiMessageDots size="20" />
          </IconButton>
        </ListItemIcon>
        <ListItemText>
          <Typography variant="inherit" noWrap>
            {session.name}
          </Typography>
        </ListItemText>
        {
          <IconButton onClick={handleClick} sx={{ color: "primary.main" }}>
            {hovering && <MoreHorizOutlinedIcon fontSize="small" />}
          </IconButton>
        }
      </MenuItem>
      <StyledMenu
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >

        <MenuItem
          key={session.id + "copy"}
          onClick={() => {
            copyMe()
            handleClose()
          }}
          disableRipple
        >
          <ListItemIcon>
            <BiDuplicate fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("copy")}</ListItemText>
        </MenuItem>

        <Divider sx={{ my: 0.5 }} />

        <MenuItem
          key={session.id + "del"}
          onClick={() => {
            setAnchorEl(null)
            handleClose()
            deleteMe()
          }}
          disableRipple
        >
          <ListItemIcon>
            <BiTrashAlt />
          </ListItemIcon>
          <ListItemText>{t("delete")}</ListItemText>
        </MenuItem>
      </StyledMenu>
    </>
  )
}
