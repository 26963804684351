import { addToast, useDialogState, useUserInfo } from "@/state"
import { userVipInfo } from "./api"

export async function loadVipInfo() {
    const id = useUserInfo.getState().user?.id
    if (id) {
        console.log("request user vip: ", id)
        const res = await userVipInfo(id)
        if (res.status === 200) {
            if (res.data.newAccount) {
                // 展示新人优惠券
                useDialogState.setState({ noviceVisible: true })
            }
            useUserInfo.getState().setVip(res.data)
        }
    }
}

export function reduceCoins(amount: number = 1, showToast: boolean = true) {
    useUserInfo.getState().decreaseTimes(amount)
    if (showToast) {
        addToast(`使用${amount}积分`, "info")
    }
}