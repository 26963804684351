import React, { Suspense, lazy, useState } from "react"

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { Control, FormProvider, useForm, useFormContext, useWatch } from "react-hook-form"
import { AiFillAlipayCircle, AiFillWechat } from "react-icons/ai"

import CoinIcon from "@/images/CoinIcon"
import { api, weixin } from "@/service"
import { addToast } from "@/state"
import { isWechat } from "@/util/env"

import useDialogState from "../state/dialog"
import { API } from "../types"
import BaseDialog from "./BaseDialog"
import CheckBox from "./CheckBox"

const PaymentPurchase = lazy(() => import("./PaymentPurchse"))

type ProductSkuType = {
  id: number
  hot?: boolean
  title: string
  price: number
  complimentary: number //免费赠送
}

const ProductList: ProductSkuType[] = [
  {
    id: 1,
    title: "120",
    complimentary: 0,
    price: 6,
  },
  {
    id: 2,
    title: "560",
    complimentary: 20,
    price: 28,
  },
  {
    id: 3,
    title: "1960",
    complimentary: 130,
    price: 98,
    hot: true,
  },
  {
    id: 4,
    title: "3760",
    complimentary: 200,
    price: 188,
  },
  {
    id: 5,
    title: "7360",
    complimentary: 400,
    price: 368,
  },
  {
    id: 6,
    title: "13320",
    complimentary: 1000,
    price: 666,
  },
]

const SectionTitle = styled(Box)({
  fontSize: "small",
  fontWeight: "bold",
  marginTop: "0.8rem",
  marginBottom: "0.2rem",
})

interface FormType {
  payType: number
  vid?: number | null
  code?: string | null
}

const HotTag = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: -5,
        right: -5,
        bgcolor: "#9E6F21",
        color: "white",
        fontWeight: "500",
        fontSize: "x-small",
        padding: 0.5,
        borderRadius: 2,
      }}
    >
      热销
    </Box>
  )
}

function usePrice(control: Control<FormType, any>) {
  /// 优惠减免金额
  const [cutPrice, setCutPrice] = React.useState(0)
  const vid = useWatch({ control, name: "vid" })
  const price = ProductList.find((i) => i.id === vid)?.price ?? 0
  const paidAmount = Math.max(0, (price * 100 - cutPrice) / 100)
  return {
    price: paidAmount,
    setCutPrice,
  }
}

type FormItemProps = {
  name: string
}

const ProductSection: React.FC<FormItemProps> = ({ name }) => {
  const { setValue } = useFormContext()
  const vid = useWatch({
    name,
  })
  return (
    <Grid container spacing={1}>
      {ProductList.map((item) => (
        <Grid key={item.id} item xs={6}>
          <CheckBox
            selected={item.id === vid}
            onClick={() => {
              setValue("vid", item.id)
            }}
          >
            <Box
              fontWeight="bold"
              fontSize="medium"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <CoinIcon />
              <Box>{item.title}</Box>
              {item.complimentary > 0 && (
                <Box ml={0.3} fontSize="small" sx={{ opacity: 0.7 }}>
                  +{item.complimentary}
                </Box>
              )}
            </Box>
            <Box fontWeight="bold" my={1} fontSize="large" textAlign="center">
              ¥{item.price}
            </Box>
            {item.hot && <HotTag />}
          </CheckBox>
        </Grid>
      ))}
    </Grid>
  )
}

const PayMethodSection: React.FC<FormItemProps> = ({ name }) => {
  const { setValue } = useFormContext()
  const value = useWatch({
    name,
  })
  const isMobile = window.innerWidth <= 600
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <CheckBox
          selected={value === 1}
          sx={{ justifyContent: "flex-start" }}
          onClick={() => {
            setValue("payType", 1)
          }}
        >
          <AiFillWechat fontSize="large" />
          <Box fontWeight="bold" ml={1}>
            微信
          </Box>
        </CheckBox>
      </Grid>
      <Grid item xs={6}>
        <CheckBox
          selected={value === 0}
          disabled={isMobile}
          sx={{ justifyContent: "flex-start" }}
          onClick={() => {
            setValue("payType", 0)
          }}
        >
          <AiFillAlipayCircle fontSize="large" />
          <Box fontWeight="bold" ml={1}>
            支付宝
          </Box>
        </CheckBox>
      </Grid>
    </Grid>
  )
}

const PaymentDialog: React.FC = () => {
  const orderVisible = useDialogState((state) => state.orderVisible)
  const [loading, setLoading] = useState(false)
  const [payment, setPayment] = React.useState<API.PayArgument | null>(null)
  const formMethods = useForm<FormType>({
    defaultValues: {
      payType: 1,
      vid: 1,
    },
  })
  const { price, setCutPrice } = usePrice(formMethods.control)
  const {
    register,
    reset,
    clearErrors,
    setError,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const onCodeChange = async (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let code = e.target.value
    if (code.length > 2) {
      const resp = await api.getCodeInfo(code)
      if (resp.status === 200 && resp.data?.parValue) {
        if (resp.data.expTime < Date.now()) {
          setError("code", { message: "兑换码已过期" })
        } else if (resp.data.del) {
          setError("code", { message: "兑换码已删除" })
        } else {
          setCutPrice(resp.data.parValue)
          clearErrors("code")
          return
        }
      } else {
        setError("code", { message: "兑换码不存在" })
      }
      setCutPrice(0)
    } else {
      clearErrors("code")
    }
  }
  const onSubmit = async (data: FormType) => {
    setLoading(true)
    console.log(data)

    new Promise((reslove) => {
      setTimeout(reslove, 2000)
    }).then(() => {
      setLoading(false)
    })
    if (!data.code || !data.code.trim()) {
      data.code = null
    }
    if (isWechat()) {
      const openid = localStorage.getItem("openid")
      if (!openid) {
        console.warn("openid获取失败")
        setError("code", { message: "请先关注公众号" })
        addToast("请关注公众号后重试", "error")
      } else {
        const { data: payParams, status } = await api.payWp(data, openid)
        if (status === 200) {
          //  price === 0 免支付
          if (payParams.price !== "0") {
            // 调用微信支付
            const { timeStamp, nonceStr, signType, paySign } = payParams
            console.log(payParams)
            weixin.pay({
              nonceStr,
              signType,
              paySign,
              timestamp: timeStamp,
              package: payParams.package,
              success: (res) => {
                console.log("前端支付成功", res)
              },
            })
          }
          setPayment(payParams)
        }
        return
      }
    }
    const resp = await api.pay(data)
    console.log(resp)
    if (resp.status < 400) {
      const payment = resp.data
      if (payment) setPayment(payment)
    } else {
      setError("code", { message: resp.statusText })
    }
  }

  const closeDialog = () => {
    useDialogState.setState({ orderVisible: false })
    setCutPrice(0)
    setPayment(null)
    reset()
  }

  return (
    <BaseDialog
      fullWidth
      autoFullScreen
      title="充值积分"
      open={orderVisible}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          closeDialog()
        }
      }}
    >
      <Divider />
      <Box my={1}>
        积分可以用于AI问答、搜索和AI绘图等场景。每次问答消耗<strong>1</strong>个积分，每次搜索消耗
        <strong>10</strong>个积分，绘图每张消耗<strong>5</strong>积分。
      </Box>
      <FormProvider {...formMethods}>
        <Box onSubmit={handleSubmit(onSubmit)} component="form">
          <SectionTitle>选择积分数量</SectionTitle>
          <input hidden {...register("vid", { required: true })} />
          <ProductSection name="vid" />
          <SectionTitle>请选择支付方式</SectionTitle>
          <input hidden {...register("payType", { required: true })} />
          <PayMethodSection name="payType" />
          <SectionTitle>兑换码（可选）</SectionTitle>
          <TextField size="small" {...register("code")} onBlur={onCodeChange} />
          {errors.code && (
            <Alert severity="error" sx={{ mt: 1 }}>
              {errors.code.message}
            </Alert>
          )}
          <Box mt={1} textAlign="end">
            <Button
              variant="contained"
              disabled={loading}
              type="submit"
              size="large"
              disableElevation
            >
              立即充值 ¥{price}
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Button>
          </Box>
          <Divider sx={{ margin: "1rem 0" }} />

          <Box mt={3}>
            <Typography variant="h6" fontSize="small">
              付款说明：
            </Typography>
            <Box color="#777" fontSize="x-small">
              <Box>
                支付表明您阅读并同意
                <Link href="https://www.qihuaitech.com/topic/terms/chat-vip-term/" target="_blank">
                  《会员服务协议》
                </Link>
                -
                <Link href="https://www.qihuaitech.com/topic/terms/chat-user-privacy/">
                  《隐私政策》
                </Link>
              </Box>
            </Box>
            <Typography variant="h6" mt={1} fontSize="small">
              其他：
            </Typography>
            <Box color="#777" fontSize="x-small">
              <span>如需更多使用量或其他商务合作请联系客服</span>
              <Button
                variant="outlined"
                size="small"
                disableElevation
                color="success"
                sx={{ borderRadius: "38px", marginLeft: 0.5 }}
                onClick={() =>
                  window.open("https://work.weixin.qq.com/kfid/kfc1aae9ba8d63c91a0", "_blank")
                }
              >
                微信客服
              </Button>
            </Box>
          </Box>
        </Box>
      </FormProvider>
      {payment && (
        <Suspense fallback={<Box p={1}>加载中...</Box>}>
          <PaymentPurchase payment={payment} handleClose={closeDialog} />
        </Suspense>
      )}
    </BaseDialog>
  )
}

export default PaymentDialog
