import { useEffect, useState } from "react"

import { Box, Button, CircularProgress, Container, Divider, Link, List, Paper } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import Block from "../components/textual/Block"
import Footer from "../components/Footer"
import { getShare } from "../service/api"
import { Message, Session, defaultConfig } from "../types"

const SharePage = () => {
  const [session, setSession] = useState<Session>()
  const [loading, setLoading] = useState(true)
  let { id } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    if (!id) return
    getShare(id).then((share) => {
      const { id, title, texts } = share.data
      const messages: Message[] = JSON.parse(texts)
      const session = {
        id: String(id),
        name: title,
        messages,
        prompt: defaultConfig.prompt,
        config: defaultConfig.session,
      }
      setLoading(false)
      setSession(session)
    })
  }, [id])

  return (
    <Box bgcolor="background.default">
      <Container maxWidth="sm">
        <Box mt="60px" pb={2}>
          <img
            src="https://h5.qihuaitech.com/files/gpt_panel_logo_2.svg"
            style={{
              width: "100%",
              height: "75px",
              objectFit: "contain",
            }}
          />
        </Box>
        <Paper
          elevation={3}
          sx={{ height: "calc(100% - 200px)", display: "flex", flexDirection: "column" }}
        >
          <Box
            fontWeight="bold"
            p={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>主题： {session?.name}</span>
            <Button variant="contained" onClick={() => navigate("/")}>
              立即使用
            </Button>
          </Box>
          <Divider />
          {loading && (
            <Box p={3}>
              <CircularProgress />
            </Box>
          )}
          <List
            className="scroll"
            sx={{
              overflow: "auto",
              paddingTop: 0,
              px: 1,
              "& ul": { padding: 0 },
              flexGrow: 2,
            }}
            component="div"
          >
            {session?.messages?.map((msg) => (
              <Block id={msg.id} key={msg.id} msg={msg} />
            ))}
          </List>
        </Paper>
        <Footer />
      </Container>
    </Box>
  )
}

export default SharePage
