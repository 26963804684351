import React, { PropsWithChildren } from "react"

import { Box } from "@mui/material"

type FormTypeProps = {
  label: string
  tip?: string
  vipIcon?: React.ReactNode 
}

const FormItem: React.FC<PropsWithChildren<FormTypeProps>> = ({ label, tip, vipIcon, children }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 1, mb: 0.5, minHeight: 50, py: 1 }}>
      <Box flexGrow={1}>
        <Box sx={{ fontSize: "large", color: "text.primary", display: 'flex' }}>
          {label}
          {vipIcon}
        </Box>
        <Box fontSize="small" color="text.secondary">
          {tip}
        </Box>
      </Box>
      {children}
    </Box>
  )
}

export default FormItem
