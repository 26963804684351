import { useEffect, useRef, useState } from "react"

import { Box, Dialog, IconButton, List, Stack, Typography, useMediaQuery } from "@mui/material"
import { useTranslation } from "react-i18next"
import { BiCog, BiListOl, BiListUl, BiShare, BiTrash } from "react-icons/bi"
import { useNavigate } from "react-router-dom"

import useScroll from "@/hooks/useScroll"
import { client } from "@/service"
import { addToast } from "@/state"
import useDialogState from "@/state/dialog"
import useSession from "@/state/session"

import { Message, Session, createMessage } from "@/types"
import CleanWindow from "../CleanWindow"
import SessionSettingsDialog from "../SessionSettings"
import ShareDialog, { useShareState } from "../ShareDialog"
import Block, { MessageActions } from "./Block"
import MessageInput from "./MessageInput"
import PromptGallery from "./PromptGallery"
import PromptView from "./PromptView"
import SessionList from "./SessionList"

const Body: React.FC = () => {
  const currentSession = useSession((state) => state.currentSession)
  const updateChatSession = useSession((state) => state.updateChatSession)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [sessionClean, setSessionClean] = useState<Session | null>(null)
  const [historyVisible, setHistoryVisible] = useState(false)

  const loading = useRef<boolean>(false)
  const [messageInput, setMessageInput] = useState("")
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))
  const { messageListRef, messageAnchorRef: messageScrollRef } = useScroll()

  // stop auto-scroll when user scroll
  useEffect(() => {
    if (!messageListRef.current) {
      return
    }
    messageListRef.current.addEventListener("wheel", function (e: any) {
      messageScrollRef.current = null
    })
  }, [])

  // 切换到当前会话，自动滚动到最后一条消息
  useEffect(() => {
    if (!messageListRef.current) {
      return
    }
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight
  }, [currentSession.id])

  const codeBlockCopyEvent = useRef((e: Event) => {
    const target: HTMLElement = e.target as HTMLElement

    const isCopyActionClassName = target.className === "copy-action"
    const isCodeBlockParent =
      target.parentElement?.parentElement?.className === "code-block-wrapper"

    // check is copy action button
    if (!(isCopyActionClassName && isCodeBlockParent)) {
      return
    }

    // got codes
    const content = target?.parentNode?.parentNode?.querySelector("code")?.innerText ?? ""

    // do copy
    // * thats lines copy from copy block content action
    navigator.clipboard.writeText(content)
    addToast(t("copied to clipboard"), "success")
  })

  // bind code block copy event on mounted
  useEffect(() => {
    document.addEventListener("click", codeBlockCopyEvent.current)

    return () => {
      document.removeEventListener("click", codeBlockCopyEvent.current)
    }
  }, [])

  /** 生成对话 */
  const generate = async (
    session: Session,
    promptMsgs: Message[],
    targetMsg: Message,
    newInput: boolean = false,
  ) => {
    messageScrollRef.current = { msgId: targetMsg.id, smooth: false }
    await client.replay(
      session,
      promptMsgs,
      ({ text, cancel }) => {
        for (let i = 0; i < session.messages.length; i++) {
          if (session.messages[i].id === targetMsg.id) {
            session.messages[i] = {
              ...session.messages[i],
              content: text,
              cancel,
              generating: true,
              waiting: false,
            }
            break
          }
        }
        updateChatSession(session)
      },
      (err) => {
        if (err.code === 999) {
          addToast("今日体验次数已经达到上限", "warning")
          navigate("/price")
          useSession.getState().deleteMessage(targetMsg.id)
        } else if (err.code === 401) {
          useSession.getState().deleteMessage(targetMsg.id)
        } else {
          for (let i = 0; i < session.messages.length; i++) {
            if (session.messages[i].id === targetMsg.id) {
              session.messages[i] = {
                ...session.messages[i],
                content: t("api request failed:") + " \n```\n" + err.msg + "\n```",
                generating: false,
                error: true,
                waiting: false,
              }
              break
            }
          }
          updateChatSession(session)
        }
      },
    )

    for (let i = 0; i < session.messages.length; i++) {
      if (session.messages[i].id === targetMsg.id) {
        session.messages[i] = {
          ...session.messages[i],
          generating: false,
        }
        break
      }
    }
    updateChatSession(session)

    // 输入框的文字生成提示，刷新不生成
    if (newInput && session.config?.suggest === true) {
      setTimeout(async () => {
        const tips = await client.generateTips(promptMsgs)
        console.log("openai completion", tips)
        if (tips.length > 0) {
          session.tips = [...tips]
          updateChatSession(session)
        }
      }, 1000)
    }

    // 会话名称自动生成
    if (!session.generatedName) {
      loading.current = true
      const name = await client.generateName(session.messages)
      console.log("生成名字", session.generatedName, "=>", name)
      if (name) {
        session.name = name.replace(/['"“”]/g, "")
        session.generatedName = name
        updateChatSession(session)
      }
    }
    messageScrollRef.current = null
  }

  const showShare = () => {
    useShareState.setState({
      visible: true,
      session: currentSession,
    })
  }
  // 消息上的操作函数
  function getActions(msg: Message, ix: number): MessageActions {
    return {
      setMsg: (updated) => {
        const curSession = useSession.getState().currentSession
        curSession.messages = curSession.messages.map((m) => {
          if (m.id === updated.id) {
            return updated
          }
          return m
        })
        updateChatSession(curSession)
      },
      delMsg: () => {
        const curSession = useSession.getState().currentSession
        curSession.messages = curSession.messages.filter((m) => m.id !== msg.id)
        updateChatSession(curSession)
      },
      refreshMsg: () => {
        const curSession = useSession.getState().currentSession
        if (msg.role === "assistant") {
          const promptMsgs = curSession.messages.slice(0, ix)
          generate(curSession, promptMsgs, msg)
        } else {
          const promptsMsgs = curSession.messages.slice(0, ix + 1)
          const newAssistantMsg = createMessage("assistant", "", false, true)
          const newMessages = [...curSession.messages]
          newMessages.splice(ix + 1, 0, newAssistantMsg)
          curSession.messages = newMessages
          updateChatSession(curSession)
          generate(curSession, promptsMsgs, newAssistantMsg)
          messageScrollRef.current = { msgId: newAssistantMsg.id, smooth: true }
        }
      },
      copyMsg: () => {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(msg.content).then(() => {
            addToast(t("copied to clipboard"), "success")
          })
        } else {
          addToast("复制失败, 请检查网站设置", "warning")
        }
      },
      quoteMsg: () => {
        let input = msg.content
          .split("\n")
          .map((line: any) => `> ${line}`)
          .join("\n")
        input += "\n\n-------------------\n\n"
        setMessageInput(input)
      },
    }
  }

  return (
    <>
      <Stack
        className="toolbar"
        direction="row"
        spacing={1}
        sx={{ py: 1, alignItems: "center", bgcolor: "background.paper" }}
      >
        <Typography
          variant="h6"
          color="inherit"
          component="div"
          noWrap
          sx={{ flexGrow: 1, flexShrink: 1, pl: 1 }}
        >
          <span style={{ cursor: "pointer" }}>{currentSession.name}</span>
        </Typography>
        <IconButton edge="start" aria-label="share" onClick={showShare}>
          <BiShare />
        </IconButton>
        <IconButton
          edge="start"
          aria-label="delete"
          onClick={() => setSessionClean(currentSession)}
        >
          <BiTrash />
        </IconButton>
        {isMobile && (
          <IconButton
            edge="start"
            aria-label="conversation"
            onClick={() => setHistoryVisible(true)}
          >
            <BiListUl />
          </IconButton>
        )}
        <IconButton
          edge="start"
          aria-label="menu"
          onClick={() => {
            useDialogState.setState({ sessionSettingVisible: true })
          }}
        >
          <BiCog />
        </IconButton>
      </Stack>
      <Box flexGrow={1} paddingBottom="68px" sx={{ overflowY: "auto" }}>
        {currentSession.inital && <PromptGallery />}
        {!currentSession.inital && (
          <List
            className="scroll"
            sx={{
              width: "100%",
              height: "100%",
              overflow: "auto",
              pt: 0,
              "& ul": { padding: 0 },
              flexGrow: 2,
            }}
            component="div"
            ref={messageListRef}
          >
            {currentSession.config && currentSession.prompt && (
              <PromptView config={currentSession.config} prompt={currentSession.prompt} />
            )}
            {currentSession.messages.map((msg, ix) => (
              <Block id={msg.id} key={msg.id} msg={msg} actions={getActions(msg, ix)} />
            ))}
          </List>
        )}
      </Box>

      <MessageInput
        messageInput={messageInput}
        setMessageInput={setMessageInput}
        tips={currentSession.tips}
        onSubmit={async (newUserMsg: Message, needGenerating = true) => {
          if (needGenerating) {
            const promptsMsgs = [...currentSession.messages, newUserMsg]
            const newAssistantMsg = createMessage("assistant", "", true, true)
            currentSession.messages = [...currentSession.messages, newUserMsg, newAssistantMsg]
            updateChatSession(currentSession)
            generate(currentSession, promptsMsgs, newAssistantMsg, true)
            messageScrollRef.current = { msgId: newAssistantMsg.id, smooth: true }
          } else {
            currentSession.messages = [...currentSession.messages, newUserMsg]
            updateChatSession(currentSession)
            messageScrollRef.current = { msgId: newUserMsg.id, smooth: true }
          }
        }}
      />
      {sessionClean !== null && (
        <CleanWindow
          open={sessionClean !== null}
          session={sessionClean}
          save={(session) => {
            sessionClean.messages.forEach((msg) => {
              msg?.cancel?.()
            })

            updateChatSession(session)
            setSessionClean(null)
          }}
          close={() => setSessionClean(null)}
        />
      )}
      <SessionSettingsDialog config={currentSession.config} prompt={currentSession.prompt} />

      <ShareDialog />
      {
        <Dialog fullScreen open={historyVisible}>
          <SessionList onClose={() => setHistoryVisible(false)} />
        </Dialog>
      }
    </>
  )
}

export default Body
