import { SvgIcon, SvgIconProps } from "@mui/material"

export default function YouhuaIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 183 46">
      <defs>
        <linearGradient
          x1="-2.22044605e-14%"
          y1="50%"
          x2="100%"
          y2="50%"
          id="linearGradient-reuts8ozhe-1"
        >
          <stop stop-color="#D1AAD7" offset="0%"></stop>
          <stop stop-color="#C88BC4" offset="26.2951404%"></stop>
          <stop stop-color="#7B8FDD" offset="77.4670154%"></stop>
          <stop stop-color="#69B8FF" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="页面" stroke="none" stroke-width="1" fill-rule="evenodd">
        <g id="平板" fill-rule="nonzero">
          <g id="ic_youhua">
            <path
              d="M12.5586818,5 L38.3364161,5.00290675 L43.3081065,42 L32.0556207,42 L30.7465156,31.9339304 L15.0372541,31.9339304 L10.6532065,42 L0,42 L14.4583388,6.82834472 L12.5586818,5 Z M67,5.00290675 L61.2981199,42 L49.3910816,42 L53.7751292,7.48526986 L51.8725631,5.00290675 L67,5.00290675 Z M28.262125,11.2756697 L23.8780774,11.2756697 L17.5972819,25.6582607 L30.1588728,25.6582607 L28.262125,11.2756697 Z"
              id="ai"
              fill="url(#linearGradient-reuts8ozhe-1)"
            ></path>
            <path
              d="M88.9685281,7.76415855 L83.6754366,46 L72,46 L76.9782292,9.88139694 L73.086417,10.5234167 L74.1699973,2.75925811 L90.0549451,0 L88.9685281,7.76415855 Z M111.126235,0 L107.865331,10.4988168 L125,10.4988168 L124.169848,16.8009715 L105.946316,16.8009715 L97.0591463,46 L85.1043956,46 L94.9496516,16.8181592 L90.3468594,16.8181592 L91.1770109,10.5160045 L97.0591463,10.5160045 L99.8111555,2.40054801 L97.8921408,0.0171876946 L111.126235,0 Z M121.244894,46 L103.159341,46 L106.968311,18.7515924 L117.938144,18.7515924 L114.955862,40.0289601 L119.65077,40.0289601 L122.379121,37.5850926 L121.244894,46 Z M115.109201,8.49681529 L114.225275,1.17197452 L123.307401,1.17197452 L124.126374,8.49681529 L115.109201,8.49681529 Z"
              id="you"
            ></path>
            <path
              d="M147.712995,7.29261364 L142.193914,46 L130,46 L135.262246,9.00852273 L130.256834,9.71022727 L131.284171,2.49147727 L148.740331,0 L147.712995,7.29261364 Z M181.766046,15.7588941 L167.599908,20.6753969 L165.21789,37.574103 L177.451501,37.574103 L180.220024,35.1498759 L178.857235,44.835443 L151.945009,44.835443 L154.713533,25.1949502 L146.983425,27.8743591 L148.223106,19.3938171 L155.881638,16.7059021 L157.943,2.42422714 L155.881638,0 L170.554527,0 L168.836725,12.251562 L183,7.27835213 L181.766046,15.7588941 Z"
              id="hua"
            ></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
