import { Box, Button, styled, useMediaQuery } from "@mui/material"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const GTAButton = styled(Button)({
  background: "linear-gradient(to right, #5FDDFD, #5FDDFD33)",
  borderRadius: 8,
  padding: "0.75rem 2rem",
  fontSize: "large",
  color: "white",
})

const LandingPage = () => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const navigate = useNavigate()

  useEffect(() => {
    const code = params.get("invite")
    if (code) {
        localStorage.setItem("invite", code)
    }
  }, [params])

  const bgImage = isMobile
    ? "https://h5.qihuaitech.com/files/ct_bg_rec_invite_mb.png"
    : "https://h5.qihuaitech.com/files/ct_bg_rec_invite_pc.png"

  return (
    <Box
      sx={{
        color: "white",
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        width: "100%",
        height: "100%",
        p: 2,
      }}
    >
      <Box fontSize="3rem" mt="20%" fontWeight="bold">
        智能引导词
        <Box
          ml={2}
          sx={{
            background: "linear-gradient(to right, hsl(98 100% 62%), hsl(204 100% 59%))",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
          }}
          component="span"
        >
          助力高效创作
        </Box>
      </Box>
      <Box fontSize="1rem" my={2} >
        文生万物是一款最新人工智能聊天软件，可以与用户进行自然语言
        交互，提供真实的沟通体验，可为用户提供更加便捷、 高效和愉悦的聊天体
      </Box>
      <GTAButton onClick={() => navigate("/")}>立即体验</GTAButton>
    </Box>
  )
}

export default LandingPage
