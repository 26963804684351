import React, { useEffect, useMemo, useRef, useState } from "react"

import { BottomNavigation, BottomNavigationAction, Box, Paper, useMediaQuery } from "@mui/material"
import { useRequest } from "ahooks"
import QueryString from "qs"
import { FaCommentDots, FaMagic, FaUserCircle } from "react-icons/fa"
import { shallow } from "zustand/shallow"

import ProfileMenu from "@/components/ProfileMenu"
import AdvanceConfig from "@/components/graphic/AdvanceConfig"
import GraphicBody from "@/components/graphic/GraphicBody"
import Body from "@/components/textual/AppBody"
import AppMenu from "@/components/textual/AppMenu"
import SessionList from "@/components/textual/SessionList"
import { WxAppId } from "@/config"
import { api, coins } from "@/service"
import useSession from "@/state/session"
import { isWechat } from "@/util/env"

import "../styles/MainPage.scss"

function MainPage() {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))
  // 在获取焦点时候刷新一次积分信息N (手机上无法定时刷新)
  useRequest(coins.loadVipInfo, {
    refreshOnWindowFocus: true,
    loadingDelay: 2000,
  })

  return <>{isMobile ? <MobileContainer /> : <PcContainer />}</>
}

async function requestCode(path: string, state: string) {
  const redirect_uri = encodeURI(path)
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WxAppId}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`
  console.log(url)

  window.location.replace(url)
}

async function loadOpenId(code: string) {
  const resp = await api.getOpenId(code)
  if (resp.data.openid) {
    localStorage.setItem("openid", resp.data.openid)
  }
}

function MobileContainer() {
  const [mode, currentSession] = useSession((state) => [state.mode, state.currentSession], shallow)
  const [tab, setTab] = useState(mode === "chat" ? 0 : 1)
  const containerRef = useRef<HTMLDivElement>()
  const code = useMemo(() => {
    if (window.location.search) {
      const params = QueryString.parse(window.location.search, { ignoreQueryPrefix: true })
      return params.code
    } else {
      return undefined
    }
  }, [window.location.search])

  useEffect(() => {
    if (!isWechat()) {
      return
    }
    const openid = localStorage.getItem("openid")
    if (!openid && !code) {
      requestCode(window.location.href, "100")
    }
    if (code && typeof code === "string") {
      loadOpenId(code)
    }
  }, [code])

  return (
    <Box className="mobileLayout" ref={containerRef}>
      {tab === 0 && <Body key={currentSession.id} />}
      {tab === 1 && <GraphicBody />}
      {tab === 2 && <ProfileMenu />}

      <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={tab}
          onChange={(event, newValue) => {
            setTab(newValue)
          }}
        >
          <BottomNavigationAction label="聊天" icon={<FaCommentDots fontSize="large" />} />
          <BottomNavigationAction label="绘图" icon={<FaMagic fontSize="large" />} />
          <BottomNavigationAction label="我的" icon={<FaUserCircle fontSize="large" />} />
        </BottomNavigation>
      </Paper>
    </Box>
  )
}

const PcContainer = () => {
  const [mode, currentSession] = useSession((state) => [state.mode, state.currentSession], shallow)

  return (
    <Box
      id="layout"
      sx={{
        display: "flex",
      }}
    >
      <Box id="left" className="open">
        <AppMenu key="app_menu" className={"sidebar open"} />
      </Box>
      <Box
        sx={{
          flex: 1,
          flexShrink: 2,
          py: 2,
          px: 0,
          maxWidth: "calc(100% - 506px)",
          height: "100%",
        }}
        component="main"
      >
        <Box
          sx={{
            height: "100%",
            maxHeight: "100%",
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 4,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            p: 2,
            pt: 0.5,
          }}
        >
          {mode === "chat" && <Body key={currentSession.id} />}
          {mode === "image" && <GraphicBody />}
        </Box>
      </Box>
      <Box
        sx={{
          bgcolor: "background.paper",
          borderRadius: 4,
          overflow: "hidden",
          m: 2,
          flexShrink: 1,
          minWidth: 250,
        }}
      >
        {mode === "chat" && <SessionList />}
        {mode === "image" && <AdvanceConfig />}
      </Box>
    </Box>
  )
}

export default MainPage
