import { create } from "zustand"
import { persist } from "zustand/middleware"

import { Session, createSession } from "../types"
import { useSettingsState } from "../components/SettingWindow"
import { ImageMessage } from "@/types/image"

type AppMode = "chat" | "image"

type UseSessionType = {
  mode: AppMode,
  chatSessions: Session[]
  currentSession: Session
  imageSession: ImageMessage[]
  createChatSession: (session: Session) => void
  updateChatSession: (session: Session) => void
  deleteChatSession: (session: Session) => void
  deleteMessage: (msgId: string) => void
}

const defaultSessionTitle = "选择场景"

export const createEmptyChatSession = () => {
  // 将默认会话配置改为系统全局设置
  const config = useSettingsState.getState()
  const session = createSession(defaultSessionTitle)
  session.config = config
  useSession.getState().createChatSession(session)
}

const initialSession = createSession(defaultSessionTitle)

export const useSession = create<UseSessionType>()(
  persist(
    (set, get) => ({
      mode: "chat",
      chatSessions: [initialSession],
      currentSession: initialSession,
      imageSession: [],
      createChatSession: (session: Session) => {
        set((state) => ({
          chatSessions: [...state.chatSessions, session],
          currentSession: session,
        }))
      },
      updateChatSession: (session: Session) => {
        if (session.messages.length > 0) {
          session.inital = false
        }
        set((state) => {
          const sessions = state.chatSessions.map((s) => {
            if (s.id === session.id) {
              return session
            }
            return s
          })
          let currentSession = state.currentSession
          if (session.id === state.currentSession.id) {
            currentSession = { ...session }
          }
          return {
            chatSessions: sessions,
            currentSession,
          }
        })
      },
      deleteChatSession: (target: Session) => {
        const state = get()
        const sessions = state.chatSessions.filter((s) => s.id !== target.id)
        if (sessions.length === 0) {
          sessions.push(createSession(defaultSessionTitle))
        }
        let currentSession = state.currentSession

        if (target.id === currentSession.id) {
          currentSession =
            sessions.find((item) => item.id !== target.id) ?? createSession(defaultSessionTitle)
        }
        set({
          chatSessions: [...sessions],
          currentSession: { ...currentSession },
        })
      },
      deleteMessage: (msgId: string) => {
        const session = get().currentSession
        const msgIndex = session.messages.findIndex((msg) => msg.id === msgId)
        if (msgIndex) {
          session.messages.splice(msgIndex, 1)
        }
        set({ currentSession: { ...session } })
      },
    }),
    {
      name: "sesssions", version: 1, migrate(persistedState, version) {
        const preState = persistedState as UseSessionType
        if (version === 1) {
          const { chatSessions, currentSession, ...rest } = preState
          return {
            chatSessions: [initialSession],
            currentSession: initialSession,
            ...rest
          }
        } else {
          return preState
        }
      },
      partialize: (state) => {
        const imageSession = state.imageSession.map(i => {
          const { initImage, ...rest } = i
          return rest
        })
        const { mode, chatSessions, currentSession } = state
        return {
          mode, chatSessions, currentSession, imageSession
        }
      }
    },
  ),
)
export default useSession
