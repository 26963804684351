import { Box } from "@mui/material"

import useDialogState from "../state/dialog"
import BaseDialog from "./BaseDialog"

type SocialMediaDialogProps = {}

const SocialMediaDialog: React.FC<SocialMediaDialogProps> = () => {
  const socialMediaVisible = useDialogState((state) => state.socialMediaVisible)
  return (
    <BaseDialog
      open={socialMediaVisible}
      title="AI交流群"
      onClose={() => useDialogState.setState({ socialMediaVisible: false })}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <img
          src="https://h5.qihuaitech.com/files/vinceness_contact_me_qr.png"
          style={{ width: '-webkit-fill-available', maxHeight: 300, objectFit: "contain" }}
          loading="lazy"
        />
        <Box fontWeight="bold" fontSize="large">
          扫描上方二维码加入微信群
        </Box>
      </Box>
    </BaseDialog>
  )
}

export default SocialMediaDialog
