import axios from "axios"
import { API } from "@/types";
import { RenderParams } from "@/types/image";
import { objectToSnake } from "@/util/snakeCase";
import { useUserInfo } from "@/state";

export const HOST = "https://www.qihuaitech.com";
const baseURL = HOST + "/gpt"
// const baseURL = "http://localhost:8050/gpt";

export const instance = axios.create({
  baseURL,
  timeout: 13000,
  validateStatus: function (status) {
    return status >= 200 && status < 500
  },
})

// code=803929：短信模板内容为密码修改，code=801677：短信模板内容为注册,code=986227：变更手机号
type CodeType = 801677 | 986227 | 803929

/** 获取验证码 */
export async function getVerificatoinCode(phone: string, type: CodeType) {
  return instance.get<any>("/auth/sendSMS", {
    params: {
      phone,
      code: type,
    },
  })
}

/** 登录 */
export async function login(phone: string, code: string, invCode?: string | null) {
  return instance.post<API.LoginResult>("/auth/loginOrRegisterBySms", {
    phone,
    code,
    invCode,
  })
}

export async function refreshToken(refresh: string) {
  return instance.post<API.LoginResult>("/auth/refresh", {
    refreshToken: refresh,
  })
}

export async function getCurrentUser() {
  return instance.get<API.UserDetail>("/user/detail")
}

/**修改用户*/
export async function editUser(user: Partial<API.UserDetail>) {
  return instance.post("/user/detail", user)
}

/** 获取产品 */
export async function getSku() {
  return instance.post("/pay/vip/list")
}

/** 获取兑换码信息 */
export async function getCodeInfo(code: string) {
  return instance.get<API.CodeInfo>("/pay/vip/code/" + code)
}

/** 支付 */
export async function pay(params: Record<string, any>) {
  // 微信返回
  // id : "824064492550793"
  // payType : "1"
  // price : "1990"
  // url : "weixin://wxpay/bizpayurl?pr=Z5m0OAezz"
  return instance.post<API.PayArgument>("/pay/buy", params)
}

/** 公众号 微信支付 */
export async function payWp(params: Record<string, any>, openid: string) {
  return instance.post("/wxAccount/buy", params, {
    headers: {
      openid
    }
  })
}

/** 查询订单状态 */
export async function getOrderInfo(id: string) {
  return instance.get<API.OrderInfo>("/pay/info/" + id)
}

/** 聊天会话 */
export async function postPrompts(body: any, controller: AbortController) {
  const token = useUserInfo.getState().token?.accessToken
  return fetch(baseURL + "/prompt/talk", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
    signal: controller.signal,
  })
}

export async function postSinglePrompt(prompt: string, n: number = 1) {
  return instance.post<{ msg: string; state: number }>("/prompt/completions", {
    // "model": "text-ada-001",
    model: "gpt-3.5-turbo",
    messages: [{ role: "system", content: prompt }],
    max_tokens: 100,
    temperature: 0.2,
    n: n,
    stream: false,
  })
}

export async function doAiAction(message: string, scenario: string) {
  return instance.post<{ msg: string; state: number }>("/prompt/action", {
    message,
    scenario
  })
}

export async function postPromptsToOpenAi(body: any, controller: AbortController) {
  // Authorization = "Bearer " + token.accessToken;
  // return fetch(`https://api.openai.com/v1/chat/completions`, {
  return fetch(`http://20.118.186.10:9090/v1/chat/completions`, {
    // return fetch(`https://vinceness-chain.azurewebsites.net/v1/chat/completions`, {
    // return fetch(`http://20.49.104.33/v1/chat/completions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
    signal: controller.signal,
  })
}

/** 用户会员信息 */
export async function userVipInfo(id: string | number) {
  return instance.get<API.VipResp>("/user/vip/" + id)
}

/**
 * 分享用户会话
 * @param params 会话信息
 * @returns
 */
export async function postShare(params: { title: string; texts: string }) {
  return instance.post("/share/info", params)
}

export async function getShare(id: string | number) {
  return instance.get<API.ShareInfo>("/share/info/" + id)
}

/** 上传图片 */
export async function uploadImage(image: Blob) {
  const formData = new FormData()
  formData.append("file", image)
  return instance
    .post("/file/uploadOssFile/img", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data.url)
}

export async function getIncomList(params: API.PaginateParams & { state: 0 | 1 }) {
  return instance.get<API.PaginateResp<API.VipOrder>>("pay/list", { params })
}

export async function getUsageList(params: API.PaginateParams) {
  return instance.get<API.PaginateResp<API.CompletionRecord>>("/prompt/record/list", { params })
}

export async function render(params: RenderParams) {
  const obj = objectToSnake(params)
  return instance.post<API.RenderTaskInfo>("/prompt/render", obj)
}

export async function translate(text: string): Promise<string> {
  return instance.post("/translator/info", { text }).then(res => {
    const data = res.data.text
    const obj = JSON.parse(data)
    return obj[0].translations[0].text
  })
}

/** 获取图片状态 */
export async function image(taskId: string | number) {
  return fetch(HOST + "/image/sse/" + taskId, {
  })
}

/*** 公众号签名 */
export async function signWechatMp(target: string) {
  return instance.get("/pet/wx/sign", {
    baseURL: "https://www.qihuaitech.com",
    params: {
      target
    }
  })
}

export async function getOpenId(code: string) {
  return instance.get("/pet/wx/openid", {
    baseURL: "https://www.qihuaitech.com",
    params: {
      code
    },
    headers: {}
  })
}