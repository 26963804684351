import { PropsWithChildren } from "react"

import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from "@mui/material"
import { BiX } from "react-icons/bi"

type BaseDialogProps = {
  autoFullScreen?: boolean
} & DialogProps

const BaseDialog: React.FC<PropsWithChildren<BaseDialogProps>> = ({
  title,
  children,
  autoFullScreen,
  ...props
}) => {
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))
  return (
    <Dialog {...props} fullScreen={autoFullScreen && isMobile}>
      <DialogTitle sx={{ mr: 3 }}>
        {title}
        <IconButton
          aria-label="close"
          onClick={(e) => props.onClose?.(e, "escapeKeyDown")}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <BiX />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{position: 'relative'}}>{children}</DialogContent>
    </Dialog>
  )
}

export default BaseDialog
