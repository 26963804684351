import { Box, Button, CircularProgress, DialogContentText, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { BiLink } from "react-icons/bi"
import { create } from "zustand"
import { shallow } from "zustand/shallow"

import { addToast } from "@/state"

import { postShare } from "../service/api"
import { Session } from "../types"
import BaseDialog from "./BaseDialog"

type UseShareDialogState = {
  visible: boolean
  session?: Session
  loading?: boolean
  id?: number | string
}

export const useShareState = create<UseShareDialogState>((set) => ({
  visible: false,
}))

const ShareDialog = () => {
  const [visible, loading, id] = useShareState(
    (state) => [state.visible, state.loading, state.id],
    shallow,
  )
  const { t } = useTranslation()
  const url = window.location.origin + "/share/" + id
  const generateUrl = async () => {
    const session = useShareState.getState().session
    if (!session) {
      return
    }
    useShareState.setState({ loading: true })
    const msgs = session.messages.map((item) => ({
      role: item.role,
      content: item.content,
      name: item.name,
      contentZh: item.contentZh,
    }))

    const resp = await postShare({
      title: session.name,
      texts: JSON.stringify(msgs),
    })
    useShareState.setState({
      loading: false,
      id: resp.data,
    })
  }
  const copy = () => {
    useShareState.setState({ visible: false }, true)
    // do copy
    // * thats lines copy from copy block content action
    navigator.clipboard.writeText(url)
    addToast(t("copied to clipboard"), "success")
  }

  return (
    <BaseDialog
      title={t("share")!}
      open={visible}
      onClose={() => useShareState.setState({ visible: false }, true)}
    >
      <DialogContentText id="alert-dialog-description" sx={{ mb: 1 }}>
        分享操作将会公开您当前的聊天记录
      </DialogContentText>

      {!id && (
        <Button
          variant="outlined"
          disabled={loading}
          onClick={generateUrl}
          startIcon={<BiLink />}
          sx={{ width: "-webkit-fill-available", mt: 1 }}
        >
          创建链接
        </Button>
      )}
      {loading && <CircularProgress size="small" />}
      {id && (
        <Stack key={id} spacing={1} direction="row" mt={1}>
          <Box
            sx={{
              p: 0.8,
              bgcolor: "background.default",
              borderRadius: 4,
              flexGrow: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {url}
          </Box>
          <Button variant="outlined" sx={{ minWidth: 100 }} onClick={copy}>
            复制链接
          </Button>
        </Stack>
      )}
    </BaseDialog>
  )
}

export default ShareDialog
