import React, { useMemo, useState } from "react"

import * as api from "@/service/api"
import { API, Settings, defaultConfig } from "@/types"
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Slider,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { BiX } from "react-icons/bi"
import { create } from "zustand"
import { persist } from "zustand/middleware"

import CoinIcon from "../images/CoinIcon"
import useDialogState from "../state/dialog"
import useUserInfo, { changeUserAttributes } from "../state/user"
import ThemeChangeButton from "../theme/ThemeChangeIcon"
import { useThemeSwicher } from "../theme/ThemeSwitcher"
import { ThemeMode } from "../theme/index"
import FormItem from "./FormItem"
import Switcher from "./Switcher"
import { addToast } from "@/state"

const models: string[] = [
  "gpt-3.5-turbo",
  "gpt-3.5-turbo-0301",
  "gpt-4",
  "gpt-4-0314",
  "gpt-4-32k",
  "gpt-4-32k-0314",
]
const languages: string[] = ["en", "zh-Hans", "zh-Hant"]
const languageMap: { [key: string]: string } = {
  en: "English",
  "zh-Hans": "简体中文",
  "zh-Hant": "繁體中文",
}

export const useSettingsState = create<Settings>()(
  persist(
    (_set) => ({
      ...defaultConfig.settings,
    }),
    { name: "settings", version: 1 },
  ),
)

export default function SettingWindow() {
  const { t } = useTranslation()
  const visible = useDialogState((state) => state.settingVisible)

  const onCancel = () => {
    useDialogState.setState({ settingVisible: false })
  }
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))
  // @ts-ignore
  // @ts-ignore
  return (
    <Dialog open={visible} onClose={onCancel} fullWidth fullScreen={isMobile}>
      <DialogTitle>
        {t("settings")}

        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <BiX />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ChatSettings />
        <Divider sx={{ my: 2 }} />
        <ProfileSettings />
        <Divider sx={{ my: 2 }} />
        <SystemSettings />
      </DialogContent>
      <Divider />
    </Dialog>
  )
}

const ChatSettings = () => {
  const settings = useSettingsState()
  return (
    <>
      <Typography variant="h6">全局聊天设置</Typography>
      <FormItem label="模型">
        <Select label="language" size="small" id="language-select" value="gpt-3.5-turbo" disabled>
          <MenuItem key="GPT3.5" value="gpt-3.5-turbo">
            GPT3.5
          </MenuItem>
          <MenuItem key="GPT4" value="gpt-4">
            GPT4
          </MenuItem>
        </Select>
      </FormItem>

      <FormItem label="关联消息" tip="支持上下文联系的最多消息条数">
        <Slider
          valueLabelDisplay="auto"
          sx={{ width: "120px" }}
          aria-label="custom thumb label"
          defaultValue={settings.maxMsgLength}
          onChangeCommitted={(_, value) =>
            useSettingsState.setState({ maxMsgLength: value as number })
          }
          min={0}
          max={20}
        />
      </FormItem>
      <FormItem label="风格">
        <ToggleButtonGroup
          color="primary"
          value={settings.temperature}
          exclusive
          aria-label="Platform"
          onChange={(_, value) => useSettingsState.setState({ temperature: value })}
        >
          <ToggleButton value={1.5}>创造力</ToggleButton>
          <ToggleButton value={0.8}>均衡</ToggleButton>
          <ToggleButton value={0}>精确</ToggleButton>
        </ToggleButtonGroup>
      </FormItem>
      <FormItem label="最大回复长度" tip="AI能回复的最大长度">
        <Slider
          valueLabelDisplay="auto"
          sx={{ width: "120px" }}
          aria-label="custom thumb label"
          min={500}
          max={4000}
          defaultValue={settings.maxTokens}
          onChangeCommitted={(_, value) =>
            useSettingsState.setState({ maxTokens: value as number })
          }
        />
      </FormItem>
      <FormItem
        label="搜索"
        vipIcon={
          <Box fontSize="small" display="flex" alignItems="center" component="span">
            <CoinIcon /> <Box color="#a16207">10</Box>
          </Box>
        }
        tip="结合搜索引擎的搜索结果，回答更全面"
      >
        <Switcher
          checked={settings.search}
          onChange={(_, search) => useSettingsState.setState({ search })}
        />
      </FormItem>
      <FormItem
        label="联想搜索"
        vipIcon={
          <Box fontSize="small" display="flex" alignItems="center" component="span">
            <CoinIcon /> <Box color="#a16207">1</Box>
          </Box>
        }
        tip="智能生成相关联的提问"
      >
        <Switcher
          checked={settings.suggest}
          onChange={(_, suggest) => useSettingsState.setState({ suggest })}
        />
      </FormItem>
    </>
  )
}

const SystemSettings = () => {
  const { t, i18n } = useTranslation()
  const settings = useSettingsState()
  const visible = useDialogState((state) => state.settingVisible)

  const [, { setMode }] = useThemeSwicher()

  // preview theme
  const changeModeWithPreview = (newMode: ThemeMode) => {
    setMode(newMode)
    useSettingsState.setState({ theme: newMode })
  }
  return (
    <>
      <Typography variant="h6" lineHeight={2}>
        系统设置
      </Typography>
      <FormItem label={t("language")}>
        <Select
          id="language-select"
          size="small"
          variant="outlined"
          value={settings.language}
          onChange={(e) => {
            const language = e.target.value
            useSettingsState.setState({ language })
            i18n.changeLanguage(language).then()
          }}
        >
          {languages.map((language) => (
            <MenuItem key={language} value={language}>
              {languageMap[language]}
            </MenuItem>
          ))}
        </Select>
      </FormItem>
      <FormItem label={"字体大小"}>
        <Select
          labelId="select-font-size"
          size="small"
          value={settings.fontSize}
          onChange={(event) => {
            useSettingsState.setState({ fontSize: event.target.value as number })
          }}
        >
          {[12, 13, 14, 15, 16, 17, 18].map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </FormItem>
      <FormItem label={t("theme")}>
        <ThemeChangeButton
          value={settings.theme}
          onChange={(theme) => changeModeWithPreview(theme)}
        />
      </FormItem>
    </>
  )
}

const ProfileSettings = () => {
  const user = useUserInfo((state) => state.user)
  const [userName, setUserName] = useState(user?.nickname)
  const { t } = useTranslation()
  const [file, setFile] = useState(null)
  const saveUser = async (key: keyof API.UserDetail, value?: string) => {
    if (value) {
      try {
        await api.editUser({ [key]: value })
        await changeUserAttributes(key, value)
        addToast("修改成功", "success")
      } catch (e) {
        addToast("修改失败", "error")
      }
    }
  }
  const handleFileChange = async (e: any) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    setFile(file)

    try {
      const url = await api.uploadImage(file)
      await api.editUser({ avatar: url })
      await changeUserAttributes("avatar", url)
      addToast("修改成功", "success")
    } catch (e) {
      addToast("修改失败", "error")
      console.warn(e)
    }
  }
  const fileUrl = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file)
    } else {
      return user?.avatar
    }
  }, [user, file])
  return (
    <>
      <Typography variant="h6">个人资料</Typography>
      <FormItem label="头像">
        <input
          accept="image/*"
          id="contained-button-file"
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <label htmlFor="contained-button-file">
          <Avatar sizes="large" src={fileUrl}></Avatar>
        </label>
      </FormItem>
      <FormItem label="用户昵称">
        <TextField
          sx={{ flexGrow: 1 }}
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          InputProps={{
            maxRows: 1,
            endAdornment: (
              <InputAdornment position="end">
                <Button variant="contained" onClick={() => saveUser("nickname", userName)}>
                  {t("save")}
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </FormItem>
    </>
  )
}
