function isObject(value: any) {
  return typeof value === "object" && value !== null && !Array.isArray(value)
}

function camelToSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export function objectToSnake(o: any): Record<string, any> | string {
  if (isObject(o)) {
    const n: Record<string, any> = {}
    Object.keys(o).forEach((k) => {
      n[camelToSnakeCase(k)] = objectToSnake(o[k])
    })
    return n
  } else if (Array.isArray(o)) {
    return o.map((i: any) => {
      return objectToSnake(i)
    })
  } else if (typeof o === "string") {
    return o
  }
  return o
}
