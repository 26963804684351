import { MutableRefObject, Ref, useEffect, useRef, useState } from "react"

import {
  Box,
  Button,
  Dialog,
  Divider,
  Drawer,
  Fade,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { BiMenu, BiTrash } from "react-icons/bi"

import useScroll from "@/hooks/useScroll"
import { useSession } from "@/state"

import BaseDialog from "../BaseDialog"
import AdvanceConfig from "./AdvanceConfig"
import GraphicBlock from "./GraphicBlock"
import MessageInput from "./MessageInput"
import "./graphic.scss"
import { useAdvanceConfigState } from "./state"

type GraphicBodyProps = {
  containerRef?: MutableRefObject<HTMLDivElement | undefined>
}

const GraphicBody: React.FC<GraphicBodyProps> = ({ containerRef }) => {
  const configVisible = useAdvanceConfigState((state) => state.configVisible)
  const imageSession = useSession((state) => state.imageSession)
  const [deleteWaring, setDeleteWaring] = useState(false)
  const cancelDelete = () => setDeleteWaring(false)
  const { messageListRef, messageAnchorRef } = useScroll()
  const currentTask = useAdvanceConfigState((state) => state.currentTask)
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))

  useEffect(() => {
    if (currentTask) {
      messageAnchorRef.current = { msgId: currentTask, smooth: true }
    } else {
      messageAnchorRef.current = null
    }
    if (isMobile) {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }, [currentTask])

  return (
    <>
      <Toolbar className="toolbar" sx={{ bgcolor: "background.paper" }}>
        <Typography variant="h6" noWrap sx={{ flexGrow: 1, flexShrink: 1 }}>
          Ai绘图
        </Typography>
        <IconButton onClick={() => setDeleteWaring(true)}>
          <BiTrash />
        </IconButton>
      </Toolbar>
      <Box ref={messageListRef} sx={{ height: "calc(100% - 73px)", overflowY: "auto", pb: 6 }}>
        {imageSession.slice(-30).map((item) => (
          <GraphicBlock key={item.requestId} msg={item} />
        ))}
      </Box>
      {isMobile && <MessageInput />}

      {
        <Dialog fullScreen open={configVisible}>
          <AdvanceConfig />
        </Dialog>
      }

      <BaseDialog open={deleteWaring} onClose={cancelDelete} title="警告">
        <Box>确认将清除所有绘画记录, 确认清空？</Box>
        <Stack direction="row" spacing={1} mt={2}>
          <Button fullWidth variant="outlined" color="info" onClick={cancelDelete}>
            取消
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="warning"
            onClick={() => {
              useSession.setState({ imageSession: [] })
              cancelDelete()
            }}
          >
            确认清空
          </Button>
        </Stack>
      </BaseDialog>
    </>
  )
}

export default GraphicBody
