import { useEffect, useState } from "react"

import { Backdrop, Box, Button, Typography } from "@mui/material"

import { api } from "@/service"
import { addToast, useDialogState, useUserInfo } from "@/state"

/** 新用户专享 */
const NoviceDialog = () => {
  const noviceVisible = useDialogState((state) => state.noviceVisible)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    // 5分钟内只弹出一次
    if (noviceVisible) {
      const lastPopTimeStr = localStorage.getItem("lastPopUpTime")
      const lastPopUpTime = lastPopTimeStr ? parseInt(lastPopTimeStr) : 0
      const now = new Date().getTime()
      const fiveMinutes = 5 * 60 * 1000
      if (!lastPopUpTime || now - lastPopUpTime > fiveMinutes) {
        setOpen(true)
        localStorage.setItem("lastPopUpTime", now.toString())
      }
    } else {
      setOpen(false)
    }
  }, [noviceVisible])

  const acquireCoupon = async () => {
    /// 直接下单购买
    const resp = await api.pay({
      type: "0",
      code: "code00",
      vid: 1,
    })
    console.log(resp)
    if (resp.status === 200) {
      useDialogState.setState({ noviceVisible: false })
      addToast("领取成功", "success")
      // 更新积分信息
      const user = useUserInfo.getState().user?.id
      if (user) {
        await new Promise((resolve) => setTimeout(resolve, 2000))
        const userVipResp = await api.userVipInfo(user)
        useUserInfo.getState().setVip(userVipResp.data)
      }
    } else {
      addToast(resp.statusText, "error")
    }
  }
  return (
    <Backdrop
      open={open}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      onClick={() => useDialogState.setState({ noviceVisible: false })}
    >
      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{
          width: 400,
          height: 440,
          backgroundSize: "contain",
          backgroundImage: "url(https://h5.qihuaitech.com/files/ct_img_novice_dialog.png)",
          color: "white",
          padding: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
          pl: 4,
        }}
      >
        <Box fontWeight="bold" fontSize="x-large">
          新人专享
        </Box>
        <Box sx={{ display: "flex", alignItems: "baseline" }}>
          <Typography
            variant="h1"
            color="#EED3B3"
            sx={{
              fontFamily: "Tahoma,Helvetica",
              "&::before": {
                content: "'¥'",
                fontWeight: "bold",
                fontSize: "x-large",
              },
            }}
          >
            120
          </Typography>
          <Box ml={2} sx={{ fontWeight: "bold", fontSize: "large" }}>
            积分
          </Box>
        </Box>
        <Button
          variant="contained"
          fullWidth
          onClick={acquireCoupon}
          sx={{
            mt: 1,
            display: "flex",
            alignItems: "center",
            fontFamily: "inherit",
            fontWeight: "bold",
            fontSize: "large",
            py: 1,
            px: 3,
            color: "#B55F2E",
            backgroundColor: "#ad5389",
            background: "linear-gradient(0deg, #F6D490, #F8E9C1)",
            border: "none",
            boxShadow: "0 0.7em 1.5em -0.5em #14a73e98",
            letterSpacing: "0.05em",
            borderRadius: "20em",
            cursor: "pointer",
            userSelect: "none",
            touchAction: "manipulation",
            "&:hover": {
              boxShadow: "0 0.5em 1.5em -0.5em #14a73e98",
            },
            "&:active": {
              boxShadow: "0 0.3em 1em -0.5em #14a73e98",
            },
          }}
        >
          免费领取
        </Button>
      </Box>
    </Backdrop>
  )
}

export default NoviceDialog
