import React, { useRef } from "react"

import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { Box, Button, Divider, IconButton, MenuList, Toolbar, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { BiPlus, BiTrash, BiX } from "react-icons/bi"
import { shallow } from "zustand/shallow"

import useSession, { createEmptyChatSession } from "@/state/session"

import { OpenAIRoleEnum, createSession } from "@/types"
import SessionItem from "../SessionItem"
import { SortableItem } from "../SortableItem"

type SessionListProps = {
  onClose?: () => void
}

const SessionList: React.FC<SessionListProps> = ({ onClose }) => {
  const [chatSessions, currentSession] = useSession(
    (state) => [state.chatSessions, state.currentSession],
    shallow,
  )

  const { t } = useTranslation()
  const sensors = useSensors(
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )
  const reversedSessions = [...chatSessions].reverse()
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (!over) {
      return
    }

    if (active.id !== over.id) {
      const oldIndex = reversedSessions.findIndex(({ id }) => id === active.id)
      const newIndex = reversedSessions.findIndex(({ id }) => id === over.id)

      const newReversed = arrayMove(reversedSessions, oldIndex, newIndex)
      useSession.setState({ chatSessions: newReversed.reverse() })
    }
  }
  const sessionListRef = useRef<HTMLDivElement>(null)

  const handleCreateNewSession = () => {
    createEmptyChatSession()
    if (sessionListRef.current) {
      sessionListRef.current.scrollTo(0, 0)
    }
  }

  const clearSessions = () => {
    const { currentSession } = useSession.getState()
    useSession.setState({ chatSessions: [currentSession] })
  }

  return (
    <Box sx={{ minWidth: 250, display: "flex", flexDirection: "column", height: "100%" }}>
      <Toolbar>
        <Typography variant="h6" noWrap sx={{ flexGrow: 1, flexShrink: 1 }}>
          聊天历史
        </Typography>
        <IconButton edge="start" aria-label="clear" onClick={clearSessions}>
          <BiTrash />
        </IconButton>
        {onClose && (
          <IconButton onClick={onClose}>
            <BiX />
          </IconButton>
        )}
      </Toolbar>
      <Divider />
      <MenuList
        sx={{
          p: 1,
          width: "100%",
          // bgcolor: 'background.paper',
          overflow: "auto",
          flex: 1,
          "& ul": { padding: 0 },
        }}
        className="scroll"
        component="div"
        ref={sessionListRef}
        // dense
      >
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={reversedSessions} strategy={verticalListSortingStrategy}>
            {reversedSessions.map((session) => (
              <SortableItem key={session.id} id={session.id}>
                <SessionItem
                  key={session.id}
                  selected={currentSession.id === session.id}
                  session={session}
                  switchMe={() => {
                    useSession.setState({ currentSession: session })
                  }}
                  deleteMe={() => useSession.getState().deleteChatSession(session)}
                  copyMe={() => {
                    const newSession = createSession(session.name + " 1")
                    newSession.messages = session.messages.filter(
                      (i) => i.role === OpenAIRoleEnum.System,
                    )
                    useSession.getState().createChatSession(newSession)
                  }}
                />
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      </MenuList>
      <Box mt={1} p={2}>
        <Button
          variant="contained"
          startIcon={<BiPlus size="20" />}
          sx={{ width: "100%", height: 48, borderRadius: 2 }}
          onClick={handleCreateNewSession}
        >
          {t("new chat")}
        </Button>
      </Box>
    </Box>
  )
}

export default SessionList
