import React, { Suspense, lazy, useCallback, useMemo, useRef, useState } from "react"

import CopyAllIcon from "@mui/icons-material/CopyAll"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import EditIcon from "@mui/icons-material/Edit"
import FormatQuoteIcon from "@mui/icons-material/FormatQuote"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import ReplayIcon from "@mui/icons-material/Replay"
import StopIcon from "@mui/icons-material/Stop"
import {
  Divider,
  IconButton,
  ListItem,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import MenuItem from "@mui/material/MenuItem"
import useResizeObserver from "@react-hook/resize-observer"
import "katex/dist/katex.min.css"
import { useTranslation } from "react-i18next"
import { BiCheckCircle } from "react-icons/bi"

import { Logo } from "@/images/LogoText"
import useUserInfo from "@/state/user"
import { Message } from "@/types"

import StyledMenu from "../StyledMenu"

const Markdown = lazy(() => import("./Markdown"))

export interface MessageActions {
  setMsg: (msg: Message) => void
  delMsg: () => void
  refreshMsg: () => void
  copyMsg: () => void
  quoteMsg: () => void
}

export interface Props {
  id?: string
  msg: Message
  actions?: MessageActions
}

type MessageProps = {
  state: ReturnType<typeof useMessageState>
  actions: MessageActions
} & Omit<Props, "actions">

const useMessageState = () => {
  const [isHovering, setIsHovering] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return {
    isHovering,
    setIsHovering,
    isEditing,
    setIsEditing,
    anchorEl,
    setAnchorEl,
    open,
    handleClick,
    handleClose,
  }
}

function _Block(props: Props) {
  const { msg } = props

  if (msg.role === "system") {
    return null
  }
  return <UserMessage {...props} />
}

const UserMessage = (props: Props) => {
  const { msg, actions } = props
  const isUserMessage = msg.role === "user"
  const messageState = useMessageState()
  const { isEditing, setIsEditing } = messageState
  const bubbleRef = useRef<HTMLElement | null>(null)
  const [dimensions, setDimensions] = useState({ width: 400, height: 40 })
  const user = useUserInfo((state) => state.user)
  const avatar = isUserMessage ? { src: user?.avatar } : { children: <Logo /> }
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("sm"))
  useResizeObserver(bubbleRef, (entry) => {
    if (entry.contentRect.width > 0 && entry.contentRect.height > 0) {
      setDimensions({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      })
    }
  })

  return (
    <ListItem
      id={props.id}
      key={msg.id}
      sx={{
        padding: "1rem 8px 0.6rem",
      }}
      className={["msg-block", msg.generating ? "rendering" : "render-done", "user-msg"].join(" ")}
    >
      <Box display="flex" flexDirection={isUserMessage ? "row-reverse" : "row"} width={1}>
        {isEditing ? (
          <Box>
            <IconButton onClick={() => setIsEditing(false)} size="large" color="primary">
              <BiCheckCircle />
            </IconButton>
          </Box>
        ) : (
          <Avatar {...avatar} />
        )}
        <Box
          mx={1}
          justifyContent="flex-end"
          sx={{
            maxWidth: "calc(100% - 50px)",
          }}
        >
          {isEditing && (
            <TextField
              multiline
              maxRows={dimensions.height / (1.4 * 18)}
              sx={{
                width: dimensions.width,
                height: dimensions.height,
                maxHeight: dimensions.height,
                "&textarea": {
                  resize: "none",
                  width: dimensions.width,
                  height: dimensions.height,
                },
              }}
              placeholder="prompt"
              value={msg.content}
              onChange={(e) => {
                actions?.setMsg({ ...msg, content: e.target.value })
              }}
              id={msg.id + "input"}
            />
          )}
          {!isEditing && (
            <Box
              display="flex"
              flexDirection="column"
              ref={bubbleRef}
              key={msg.id}
              sx={{
                wordBreak: "break-word",
                bgcolor: (theme) =>
                  isUserMessage
                    ? theme.palette.mode === "dark"
                      ? "primary.dark"
                      : "primary.main"
                    : isMobile
                    ? "background.paper"
                    : "background.default",
                color: isUserMessage ? "white" : "inhert",
                borderRadius: isUserMessage ? "18px 4px 18px 18px" : "4px 18px 18px 18px",
                padding: "2px 12px 12px",
                lineHeight: 1.4,
                minWidth: 200,
              }}
            >
              <Suspense fallback={<Box p={1}>加载中...</Box>}>
                <Markdown content={msg.content} />
              </Suspense>
              {msg.waiting && (
                <>
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem", width: "40%" }} />
                </>
              )}
              {actions && <MenuList {...props} actions={actions} state={messageState} />}
            </Box>
          )}

          {/* {((isHovering && !isEditing) || msg.generating) && <MessageMenu {...props} state={messageState} />} */}
        </Box>
        {actions && <SubMenu {...props} actions={actions} state={messageState} />}
      </Box>
    </ListItem>
  )
}

const MenuList: React.FC<MessageProps> = ({ msg, actions, state }) => {
  const { refreshMsg, copyMsg } = actions
  const { t } = useTranslation()
  const { setAnchorEl, handleClick } = state
  const [disable, setDisable] = useState(false)
  // stop action
  const isUserMessage = msg.role === "user"

  const onStop = useCallback(() => {
    setDisable(true)
    setTimeout(() => {
      setDisable(false)
    }, 2000)
    msg?.cancel?.()
  }, [msg])

  const onRefresh = useCallback(() => {
    setDisable(true)
    setTimeout(() => {
      setDisable(false)
    }, 2000)
    onStop()
    refreshMsg()
  }, [onStop, refreshMsg])
  return (
    <Stack direction="row" spacing="2px" justifyContent={isUserMessage ? "flex-end" : "flex"}>
      {msg.generating ? (
        <Tooltip title={t("stop generating")} placement="top">
          <IconButton
            disabled={disable}
            aria-label="stop"
            color={disable ? "default" : "warning"}
            onClick={onStop}
          >
            <StopIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={t("regenerate")} placement="top">
          <IconButton disabled={disable} aria-label="regenerate" onClick={onRefresh}>
            <ReplayIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <IconButton
        aria-label="copy"
        onClick={() => {
          copyMsg()
          setAnchorEl(null)
        }}
      >
        <CopyAllIcon fontSize="small" />
      </IconButton>
      <IconButton onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
    </Stack>
  )
}

const SubMenu: React.FC<MessageProps> = ({ msg, actions, state }) => {
  const { quoteMsg, delMsg } = actions
  const { t } = useTranslation()
  const { anchorEl, open, setIsEditing, setIsHovering, setAnchorEl, handleClose } = state
  return (
    <StyledMenu
      MenuListProps={{
        "aria-labelledby": "demo-customized-button",
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      key={msg.id + "menu"}
    >
      <MenuItem
        onClick={() => {
          setIsHovering(false)
          setAnchorEl(null)
          setIsEditing(true)
        }}
      >
        <EditIcon fontSize="small" />
        {t("edit")}
      </MenuItem>

      <MenuItem
        key={msg.id + "quote"}
        onClick={() => {
          setIsHovering(false)
          setAnchorEl(null)
          quoteMsg()
        }}
        disableRipple
      >
        <FormatQuoteIcon fontSize="small" />
        {t("quote")}
      </MenuItem>

      <Divider sx={{ my: 0.5 }} />
      <MenuItem
        key={msg.id + "del"}
        onClick={() => {
          setIsEditing(false)
          setIsHovering(false)
          setAnchorEl(null)
          delMsg()
        }}
        disableRipple
      >
        <DeleteForeverIcon fontSize="small" />
        {t("delete")}
      </MenuItem>
    </StyledMenu>
  )
}

export default function Block(props: Props) {
  return useMemo(() => {
    return <_Block {...props} />
  }, [props.msg])
}
