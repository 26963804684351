import React, { useState } from "react"

import CloseIcon from "@mui/icons-material/Close"
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  TextField,
  styled,
} from "@mui/material"
import { create } from "zustand"

import { getCurrentUser, getVerificatoinCode, login, userVipInfo } from "../service/api"
import { useUserInfo, useDialogState } from "@/state"

const phonePattern = /^1\d{10}$/

interface UseLoginState {
  wrongPhone: boolean
  wrongCode: boolean
  alertMsg: string | undefined | null
  alertLevel: AlertColor
  phoneNumber?: string
  verificationCode?: string
  aggreeUserTerm: boolean
}

type VerificationCodeState = {
  remainingTime: number
  isRetrieving: boolean
}

export const useLogin = create<UseLoginState>((set) => ({
  wrongPhone: false,
  wrongCode: false,
  alertMsg: null,
  phoneNumber: undefined,
  verificationCode: undefined,
  alertLevel: "error",
  aggreeUserTerm: false,
}))

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}))

const InputText = styled(TextField)({
  "& .MuiFilledInput-root:before": {
    borderBottom: "none",
  },
})

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const LoginDialog: React.FC = () => {
  const { wrongPhone, wrongCode, alertMsg, phoneNumber, verificationCode, alertLevel } = useLogin()
  const loginVisible = useDialogState((state) => state.loginVisible)

  const handleClickOpen = () => {
    useDialogState.setState({ loginVisible: true })
  }
  const handleClose = () => {
    useDialogState.setState({ loginVisible: false })
  }
  const [countdown, setCountdown] = useState(60)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const handlePhoneNumberChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const phoneNumber = event.target.value
    if (phoneNumber.length >= 11) {
      if (!phonePattern.test(phoneNumber)) {
        useLogin.setState({
          phoneNumber,
          wrongPhone: true,
          alertMsg: "请输入11位有效的手机号码",
          alertLevel: "error",
        })
      } else {
        useLogin.setState({ phoneNumber, wrongPhone: false, alertMsg: null })
      }
    } else {
      useLogin.setState({ phoneNumber })
    }
  }

  const handleVerificationCodeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    useLogin.setState({ verificationCode: event.target.value })
  }

  const handleCountdownTick = () => {
    setCountdown((countdown) => countdown - 1)
  }

  const handleSendVerificationCodeClick = () => {
    // 发送验证码，并启动 60 秒倒计时
    // 如果已经在倒计时了，直接返回
    if (isButtonDisabled) {
      return
    }

    // 验证手机号码是否合法
    if (!phoneNumber || !phonePattern.test(phoneNumber)) {
      console.info("phone", phoneNumber)

      useLogin.setState({
        wrongPhone: true,
        alertMsg: "请输入11位有效的手机号码",
        alertLevel: "error",
      })
      return
    }

    setCountdown(60)
    setIsButtonDisabled(true)

    const countdownTimer = setInterval(() => {
      handleCountdownTick()
    }, 1000)

    setTimeout(() => {
      clearInterval(countdownTimer)
      setIsButtonDisabled(false)
    }, 60000)

    // 发送验证码
    getVerificatoinCode(phoneNumber, 801677)
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
          useLogin.setState({ alertMsg: `验证码已发送至 ${phoneNumber}`, alertLevel: "success" })
        } else {
          useLogin.setState({ alertMsg: res.data.msg ?? "请求出现错误", alertLevel: "error" })
          clearInterval(countdownTimer)
          setIsButtonDisabled(false)
        }
      })
      .catch((e) => {
        console.error("出现错误", e)
      })
  }

  const handleLoginFormSubmit = async () => {
    // 验证表单输入是否合法，并提交登录请求
    const { aggreeUserTerm, phoneNumber, verificationCode } = useLogin.getState()

    if (!phoneNumber || !phonePattern.test(phoneNumber)) {
      useLogin.setState({ alertMsg: "请填写正确的手机号", alertLevel: "warning" })
      return
    }
    if (!verificationCode) {
      useLogin.setState({ alertMsg: "请填写验证码", alertLevel: "warning" })
      return
    }
    if (!aggreeUserTerm) {
      useLogin.setState({ alertMsg: "请阅读相关协议并在同意后勾选同意选项", alertLevel: "warning" })
      return
    }
    useLogin.setState({ alertMsg: null })
    // 邀请码
    const invCode = localStorage.getItem("invite")
    const resp = await login(phoneNumber, verificationCode, invCode)
    if (resp.status === 200) {
      const token = resp.data?.accessToken
      if (token) {
        useUserInfo.setState({ token: resp.data })

        localStorage.removeItem("invite")
        const user = (await getCurrentUser()).data
        if (user) {
          useUserInfo.setState({ user })
          const vipResp = await userVipInfo(user.id)
          if (vipResp.data) {
            useUserInfo.getState().setVip(vipResp.data)
          }
        }
      }
      useLogin.setState({ alertMsg: null })
      handleClose()
    } else {
      useLogin.setState({ alertMsg: resp.statusText, alertLevel: "error" })
    }
  }

  //   const { isRetrieving: isRetrievingVerificationCode, remainingTime } = verificationCodeState;
  return (
    <BootstrapDialog open={loginVisible} title="登录" onClose={handleClickOpen}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        登录
      </BootstrapDialogTitle>
      <DialogContent>
        <Stack flexDirection="column" spacing={1.5}>
          {alertMsg && <Alert severity={alertLevel}>{alertMsg}</Alert>}
          <InputText
            label="手机号"
            type="tel"
            variant="filled"
            placeholder="请输入手机号"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            inputProps={{ pattern: "^1\\d{10}$" }}
            error={wrongPhone}
          />
          <Stack direction="row" display="flex" alignItems="center" spacing={2}>
            <InputText
              label="验证码"
              type="number"
              variant="filled"
              value={verificationCode}
              onChange={handleVerificationCodeChange}
              required
              error={wrongCode}
              inputProps={{ pattern: "^\\d{6}$" }}
              sx={{ flexGrow: 1 }}
            />

            <Button
              disabled={isButtonDisabled}
              onClick={handleSendVerificationCodeClick}
              variant="contained"
              size="medium"
            >
              {isButtonDisabled ? `${countdown}秒` : "获取验证码"}
            </Button>
          </Stack>
          <UserTermLine />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleLoginFormSubmit}
          size="large"
          sx={{ width: "-webkit-fill-available" }}
        >
          登录
        </Button>
      </DialogActions>
    </BootstrapDialog>
  )
}

const UserTermLine = () => {
  return (
    <Box fontSize="small" color="#777">
      <Checkbox onChange={(e) => useLogin.setState({ aggreeUserTerm: e.target.checked })} />
      同意
      <Link underline="hover" href="https://www.qihuaitech.com/topic/terms/chat-service-term/">
        《用户使用协议》
      </Link>
      和
      <Link underline="hover" href="https://www.qihuaitech.com/topic/terms/chat-user-privacy/">
        《隐私条款》
      </Link>
    </Box>
  )
}

export default LoginDialog
