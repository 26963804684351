import { WxAppId } from "@/config"
import wx from "weixin-js-sdk-ts"
import * as api from "./api"

export async function configWX(url: string) {
    const resp = await api.signWechatMp(url)
    const config = resp.data
    wx.config({
        debug: false,
        appId: WxAppId,
        jsApiList: ["chooseWXPay"],
        ...config
    })
}

export async function pay(params: wx.IchooseWXPay) {
    wx.checkJsApi({
        jsApiList: ["chooseWXPay"],
        success(res) {
            if (res.checkResult['chooseWXPay']) {
                console.log("前端call wx pay", params);
                wx.chooseWXPay(params)
            }
        },
    })
}

export default { configWX, pay }