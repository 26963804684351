import React from "react"

import { Alert, AlertTitle, Chip, IconButton, styled } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import Box from "@mui/material/Box"
import "katex/dist/katex.min.css"
import { useTranslation } from "react-i18next"
import { BiDirections, BiEditAlt } from "react-icons/bi"
import { RiVipCrown2Fill } from "react-icons/ri"

import useDialogState from "@/state/dialog"

import { Prompt, SessionConfig } from "@/types"

const ChipItem = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
}))

type PromptViewProps = {
  prompt: Prompt
  config: SessionConfig
}

const PromptView: React.FC<PromptViewProps> = ({ prompt, config }) => {
  const { i18n } = useTranslation()
  const content = i18n.language === "en" ? prompt.description : prompt.desc_cn
  const showSettings = () => useDialogState.setState({ sessionSettingVisible: true })
  return (
    <Box p={1}>
      <Alert severity="info" icon={<BiDirections />}>
        <AlertTitle>场景</AlertTitle>
        <Box className="line-3">{content}</Box>
        <Box mt={0.5} onClick={showSettings}>
          <ChipItem label="模型: GPT3.5" />
          {config.search && (
            <ChipItem
              label="搜索"
              avatar={
                <Avatar sx={{ bgcolor: "white" }}>
                  <RiVipCrown2Fill color="#eab308" size={16} />
                </Avatar>
              }
            />
          )}
          <ChipItem label={"关联消息:" + config.maxMsgLength} />
          <ChipItem label={"最大回复长度: " + config.maxTokens} />
          <ChipItem label={getTemperatureLabel(config.temperature)} />
          {config.suggest && <ChipItem label="联想搜索" />}
        </Box>
      </Alert>
    </Box>
  )
}

const getTemperatureLabel = (temperature: number) => {
  if (temperature < 0.3) {
    return "精确"
  } else if (temperature > 0.8) {
    return "创造力"
  } else {
    return "均衡"
  }
}

export default PromptView
